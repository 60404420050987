:root {
    --color-febd69: #febd69;
    --color-232f3e: #0c0c0cfc;
    --color-f5f5f7: #f5f5f7;
    --color-ededed: #ededed;
  }


  @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;700&display=swap');



.best-sellers-collection{
    background-color: var(--color-232f3e);
    padding: 2em;
    border-radius: 2em;
    height: 80vh;
}


.bestseller-wrapper {
    position: relative;
    overflow: hidden;
    background-color: var(--color-232f3e);
}

.best-sellers-collection h2 {
    color: var(--color-ededed);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 700;
}

.best-sellers-collection p{
    color: var(--color-ededed);
    font-size: 16px;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

 
.bestseller-collection {
    background-color: var(--color-232f3e);
    gap: 1rem; /* Space between items */
    
    padding: 2rem; /* Optional padding for better look */
  
}

 
.bestseller-collection .slick-slide {
    display: flex;
    justify-content: center; /* Center the content of each slide */
    align-items: center;
}


/* Change the default dot color */
.slick-dots li button:before {
    color: #554e44; /* Replace with your desired color */
    font-size: 10px; /* Optional: Adjust the size of the dots */
}

/* Change the active dot color */
.slick-dots li.slick-active button:before {
    color: #dddfe0; /* Replace with your desired active dot color */
}

 
 
.best-sellers-collection .product-card {
    flex: 0 0 auto; /* Ensure items don't shrink */
    width: 254px; /* Adjust as needed */
    margin-right: 10px; 
    height: 330px;
    padding: 0px;
    border-radius: 1.2em;
    background-color: transparent;
}

 

.best-sellers-collection .product-card img{
    transform: scale(1) !important;
 }


.best-sellers-collection .product-card:hover img{
   padding: 0;
   transform: scale(1) !important;
}

.best-sellers-collection .product-image {
    height: 254px !important;
    position: relative;
    border-radius: 1em;
    background-color: transparent;
    
}

.best-sellers-collection img {
    object-fit: cover !important;
    border-radius: 1.2em;
     
}

 
.add-to-cart-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    background-color: var(--color-232f3e) !important;
    color: white;
    border: none;
    text-align: center;
    padding: 0;
    overflow: hidden; /* Prevent any content from spilling out when the height is 0 */
    transition: height 0.3s ease-out, padding 0.3s ease-out; /* Smooth transition for height and padding */
}

.product-card:hover .add-to-cart-btn {
    height: 2.2em; /* Set the desired height on hover */
    padding: 0.5em 0; /* Optional: Add padding when button is visible */
}


.bs-cart-icon {
    font-size: 1.5em;
    opacity: 0; /* Hide the content initially */
    transition: opacity 0.3s ease-out; /* Smooth transition for opacity */
}

 
.product-card:hover .add-to-cart-btn .bs-cart-icon {
    opacity: 1;  
}


.best-sellers-collection .product-title ,
.best-sellers-collection .price,
.best-sellers-collection .previous-price{
    display: flex;
    justify-content: center;
    color: var(--color-ededed) !important;
}

/* General Styles for Arrows */
.prev-arrow, .next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    border-radius: 50%; /* Make it circular */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Hover Effect */
  .prev-arrow:hover, .next-arrow:hover {
    background-color: rgba(0, 0, 0, 0.8); /* Darker on hover */
  }
  
  /* Positioning */
  .prev-arrow {
    left: -30px; /* Adjust this to move the arrow closer/further from the slider */
  }
  
  .next-arrow {
    right: -30px; /* Adjust this to move the arrow closer/further from the slider */
  }
  
  /* Icon Styles */
  .arrow-icon, .next-arrow-icon {
    color: white; /* Icon color */
    font-size: 24px; /* Icon size */
    transition: transform 0.3s ease;
  }
  
  /* Hover Effect on Icon */
  .prev-arrow:hover .arrow-icon, .next-arrow:hover .next-arrow-icon {
    transform: scale(1.2); /* Slightly enlarge on hover */
  }
  

  
@media screen and (max-width:784px) {
    
    .best-sellers-collection{
 
        height: 70vh;
   
        padding: 2em 0.5em;
    }
    
  .bestseller-wrapper {
    padding: 0px !important;
  }

  .best-sellers-collection .product-card {
    height: 360px;
  }

  .best-sellers-collection{
   
    border-radius: 1em;
  
}

.best-sellers-collection .product-card {

    border-radius: 1em;

}


.best-sellers-collection img {
    border-radius: 1.2em;
     
}

.add-to-cart-btn {
    position: relative;
    height: 2.2em;
   background-color: #dddfe0;
   border: 1px solid var(--color-ededed);
   margin-top: 10px;
   border-radius: 0.5em;
  }

  .bs-cart-icon {
    opacity: 1;
  }

  .bestseller-collection {
 
    
    padding: 1rem; /* Optional padding for better look */
    overflow: hidden;
}


  .prev-arrow {
    left: -25px; /* Adjust this to move the arrow closer/further from the slider */
  }
  
  .next-arrow {
    right: -25px; /* Adjust this to move the arrow closer/further from the slider */
  }
  

}

