:root {
    --color-0c1315: #0c1315;
    --color-BFA37C: #BFA37C;
    --color-febd69: #febd69;
  }
  
  
  @import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@100..900&display=swap');
  
  @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;700&display=swap');


  
  .hero__slider-section {
    height: 100vh;
  }
  
  .slider__item {
    max-width: 100%;
    height: 90vh;
  }
  
  .slider__item-01 {
    background: linear-gradient(rgb(0, 0, 0, 0.6), rgb(0, 0, 0, 0.6)),
      url("../images/cover-1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .slider__item-02 {
    background: linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)),
    url("../images/cover-3.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .slider__item-03 {
    background: linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)),
    url("../images/cover-2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  
  .hero-wrapper {
   position: relative;
   align-items: center;
   justify-content: center;
   
  }
  
  .hero-intro {
    position: absolute;
    max-width: 585px;
    text-align: left;
    padding: 20px;
    
    bottom: 25%;
  }
  
  .hero-intro h2 {
    font-size: 2.5rem;
    margin-bottom: 15px;
    color: #fff;
    font-weight: 700;
    font-family: 'Plus Jakarta Sans', sans-serif;

  }
  
  .hero-intro p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #d8d8d8;
    font-family: 'Plus Jakarta Sans', sans-serif;

  }
  
  .shop-now-button {
    background-color:var(--color-0c1315);
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .shop-now-button:hover {
    background-color: var(--color-febd69);
    color: #fff;
  }
  
  
  
  .hero__slider {
    overflow: hidden;
  }
  
  .slider__content {
    padding-top: 150px;
  }

 
  
  .slider__content h1 {
    font-size: 3.2rem;
    font-weight: 600;
  }
  
  .reserve__btn {
    background: #fff !important;
    color: var(--color-0c1315) !important;
    border: none;
    outline: none;
  }
  
  .reserve__btn a {
    text-decoration: none;
    font-weight: 600;
    color: var(--color-0c1315);
  }
  
  .reserve__btn:hover {
    background: #000d6b;
    color: #fff;
  }
  
  @media only screen and (max-width: 992px) {
    .slider__item {
      height: 530px;
      max-width: 100vw;
    }
  
    .slider__content {
      margin-top: -50px;
    }
  
    .slider__content h1 {
      font-size: 2rem;
    }
  }
  
  @media only screen and (max-width: 768px) {
  
  
    .hero__slider-section {
      height: 100%;
    }
  
    .slider__item {
      height: 450px;
    }
  
    .slider__content h1 {
      font-size: 1.5rem;
    }


      
  .hero-intro {
 
    max-width: 585px;
    text-align: left;
    padding: 20px;
    
    bottom: 10%;
  }
  
  .hero-intro h2 {
    font-size: 2rem;
 
    
    font-weight: 700;
 
  }
  
  .hero-intro p {
    font-size: 1.2rem;
 
  }
    
  }
  
  
  @media (max-width:840px) {
  
  .hero__slider >div{
    height: 460px;
    object-fit: contain;
  }
  
  }