:root {
    --color-febd69: #febd69;
}



 

.featured{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    
  }

  .featured-collection .col-3 {
    height: 320px;
    width: 100%;
  }

  .featured-collection .product-card {
    width: 100%;
 
    flex-shrink: none;
   
  }

  .featured-collection .product-image {
    height: 220px !important;
  }

.collection-wrapper h2{
font-size: 1.5em;
}
 

.collection-wrapper li {
    cursor: pointer;
}

.button-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
   
}


.button-container button {
    padding: 0px 10px;
    border: none;
    background-color: var(--color-febd69);
    float: left;
    
}

.button-container button:hover {
    background-color: #febd69d7;
}
 

 

.featured-collection img {
    object-fit: cover !important;
}

@media screen and (max-width:784px) {
    
    .featured-collection{
     
        grid-template-columns: repeat(2, 1fr);
        height: max-content;
        
      }

    

      .collection-wrapper{
        width: 100%;
     
      }

    
    
      
      .featured-wrapper {
        background-color: aqua;
        width: 100%;
      }

      .featured-collection{
        width: 100%;
        background-color: #febd69;
       
      }

     .featured-collection .col-x{
        height: 320px;
        width: 90%;
        background-color: red;
        
      }
      
      .featured-collection .product-card{
        margin: 0px;
        height: 100%;
        padding: 5px;
        width: 100%;
      }

       
      .collection-wrapper ul {
        display: flex;
        overflow-x: scroll;
        background-color: #febd69;
      }
}