* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



body{
  overflow-x: hidden;
}
/*@import '@fortawesome/fontawesome-free/css/all.css';*/
/*cool*/

:root {
  --color-131921: black;
  --color-3b4149: #3b4149;
  --color-febd69: #febd69;
  --color-232f3e: #0c0c0cfc;
  --color-bf4800: #0c0907;
  --color-f5f5f7: #f9f9f9;
  --color-ededed: #ededed;
  --color-777777: #777777;
  --color-1c1c1b: #1c1c1b;
}


@font-face {
  font-family: 'Satoshi';
  src: url('../public/Satoshi_Complete/Fonts/OTF/Satoshi-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../public/Satoshi_Complete/Fonts/OTF/Satoshi-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../public/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf') format('opentype');
  font-weight: 500; /* Typically, 'medium' is represented by 500 */
  font-style: normal;
}



:root {

  --transition-3: 1s ease;
}


[data-reveal] {
  transform: translateY(5px);
  opacity: 0;
  transition: var(--transition-3);
}

[data-reveal="left"] { transform:  translateY(5px); }

[data-reveal="right"] { transform: translate(50px, 0); }

[data-reveal].revealed {
  transform: translate(0, 0);
  opacity: 1;
}

.errors{
  color: red !important;
  font-size: 12px !important;
}
.section-heading {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}
.card-wrapper {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 0px 15px;
}
body {
  font-family: "Rubik", sans-serif;
  background-color: var(--color-ededed);
}


a {
  text-decoration: none;
  display: inline-block;
}
.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}

.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 15px;
}
.gap-30 {
  gap: 30px;
}

.button {
  background-color: var(--color-232f3e);
  color: white;
  font-size: 14px;
  padding: 10px 28px;
  border-radius: 25px;
}
.button:hover {
  background-color: var(--color-febd69);
  color: #131921;
}

.col-3{
  width: 250px;
}

.breadcrumb{
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.breadcrumb p {
  display: flex;
  align-items: center;
}


.checkout-wrapper {
  padding-top: 0px !important;
}

.review-inner-wrapper {
  margin-bottom: 10px;
}

.description {
  padding-top: 6px !important;
}



.address-container {
  width: 65% !important;
}



.warning-message {
  position: fixed;
  top: 50%;
  right: 50%;
  background-color: white;
  color: gold;
  padding: 5px 10px;
  border-radius: 5px;
  font-size:14px;
  z-index: 1000;
  border:1px solid gold;
}

/************ Loader Css ************/

.loader{
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.ring{
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}

@keyframes ring{
  
  0%{
    transform: rotate(0deg);
    box-shadow: 1px 5px 2px #e65c00;
  }
  50%{
    transform: rotate(180deg);
    box-shadow: 1px 5px 2px #18b201;
  }

  100%{
    transform: rotate(360deg);
    box-shadow: 1px 5px 2px #0456c8;
  }


}

.ring:before{
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}

.loader p{
  color: #737373;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 200px;
  animation: text 3s ease-in-out linear;
}

@keyframes text{

  50%{
    color: black;
  }

}


/* Header Part */



.breadcrumb .col-12 {
  padding: 0px !important;
}

.header-top-strip,
.header-top-strip2 {
  border-bottom: 1px solid var(--color-3b4149);
  background-color: var(--color-131921);
}

.header-top-strip,
.header-top-strip2 {
padding: 10px;
}

.header-upper,
.header-upper2 {
  background-color: var(--color-131921);
}
.header-bottom,
.header-bottom2 {
  background-color: var(--color-232f3e);
}
.header-upper .input-group-text ,
.header-upper2 .input-group-text {

  background-color: #000000;
  color: #ffff;
}
.header-upper.header-upper-links img ,
.header-upper2 .header-upper-links img {
  width: 35px;
  height: 35px;
}
.header-bottom .menu-links a,
.header-bottom2 .menu-links a,
.header-bottom .dropdown button, 
.header-bottom2 .dropdown button {
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.3;
  text-transform: uppercase;
}

.header-bottom .dropdown button:focus ,
.header-bottom2 .dropdown button:focus {
  box-shadow: none;
}
.header-bottom .dropdown-menu ,
.header-bottom2 .dropdown-menu {
  background-color: var(--color-131921);
  width: 100%;
  transform: translate3d(0px, 57px, 0px) !important;
}
.header-bottom .dropdown-item ,
.header-bottom2 .dropdown-item {
  padding: 20px;
  margin-bottom: 3px;
  border-bottom: 1px solid var(--color-3b4149);
}
.header-bottom .dropdown-item:hover ,
.header-bottom2 .dropdown-item:hover {
  background-color: transparent;
  color: var(--color-febd69) !important;
}


.h-upperlinks1{
  display: flex;
  justify-content: right;
}

.header-upper-links{
  justify-content: right;
  gap: 20px;
  width: 60%;
  display: flex;
  align-items: baseline;
}

.cart-el{
  height: 100%;
  display: flex;
  align-items: center;
}

.login-icon{
  display: flex;
  align-items: center;
}


.menu-bottom{
  align-items: center;
  justify-content: end;
  display:flex;
}

.menu-links{
  display: flex;
  width: 42%;
  justify-content: space-between;
  flex-shrink: inherit;
}

.menu-links a:hover {
  color: var(--color-febd69);
}

.menu-links-nav{
  flex-grow: 1;
  justify-content: space-between;
}


.custom-dropdown-toggle {
  outline: none !important;
  box-shadow: none !important;
}

.custom-dropdown-toggle:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Header Part End Here */


/* Footer Part */

.footer-2{
  width: 100%;
  justify-content: space-between;
}

.left-footer{
  width: 100%;
}

footer {
  background-color: var(--color-232f3e);
  width: 100%;
}


.footer-one {
width: 100%;
justify-content: space-between;
padding: 0px 10px;
}

.footer-logo {
  width: 20%;
  color: white;
}

.newsletter-container {
  display: flex;
  width: 65%;
}

.footer-one .col-5 {
  width: 40%;
  text-align: right;
  display: flex;
}

.footer-one h2 {
  font-size: 25px;

}

.footer-one .col-7 {
  flex-grow: 1;
}

footer:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
footer .input-group-text {
  background-color: var(--color-232f3e);
  color: white;
  padding: 8px 17px;
}

.form-control{
  margin: 0px !important;
}
/* Footer Part Ends Here */

/* Home Page */


/************ Top Cover Wrapper ************/


.home-wrapper-1 {
  padding: 0px 5px;
}

.top-cover-wrapper {
  width: 100%;
  height: 80vh;
  gap: 10px;
}

.top-cover-1 {
  width: 50%;
  position: relative;
  border-radius: 10px;
}

.top-cover-1 img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}



.section-3{
  height: 350px;
}

.section-3 .cover {
  height: 100%;
}


.sports-wrapper > div {
  padding: 0px 5px;
}

.cover-image {
  border-radius: 10px;
}

.cover-wrapper{
  width: 100%;
  display: flex;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
}

.cover {
  width: 100%;
  height:312px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

}

.cover-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: ease-in-out 0.2s;
  border-radius: 10px;
}




.cover img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.cover-content {
  position: absolute;
  top: 15%;
  left: 4%;
}

.cover-content-2 {
  position: absolute;

  text-align: center;
  width: max-content;
}



.cover-content h2,
.cover-content-2 h2 {
  color: white;
  font-size: 25px;
}

.cover-button {
  background-color: transparent;
  border: none;
  color: white;
}

.cover-button-2 {
  padding: 3px 20px;
  transition: ease-in-out 0.2s;
  border: 2px solid var(--color-232f3e);
  color: white;
  background-color: var(--color-232f3e);
  border-radius: 20px;
}

.cover-button-2:hover {
  background-color: var(--color-febd69);
  border: 2px solid var(--color-febd69);
  color: var(--color-232f3e);
}

.cover-button:hover {
  color: rgb(199, 199, 199);
}

.collection-wrapper{
  margin-top: 2em;
}

.collection-wrapper ul{
 padding: 0;
 list-style: none;
 display: flex;
 gap: 1em;
  
 border-bottom: 1px solid #d3d3d3;
}

.collection-wrapper li {
 
  line-height: 2.5em;
  width: 115px;
  text-align: center;
}




.section-6 {
  margin: 30px 0px;
  width: 100%;
  gap: 20px;
  height: 650px;
}

.single-cover {
  width: 50%;
  height: 100%;}

.single-cover .cover {
  height: 100%;
}

.other-covers {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.other-covers .cover {
  height: 48.42% ;
}
/* home wrapper 2 */
.home-wrapper-2 {
  background-color: var(--color-ededed);
  margin-bottom: 10px;
}

/* home wrapper 2 ends */

/* categories section */

.categories {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}
.categories > div {
  width: 25%;
  padding: 10px 10px 10px 20px;
}
.categories > div > div {
  width: 60%;
}
.categories > div:nth-child(1),
.categories > div:nth-child(2),
.categories > div:nth-child(3),
.categories > div:nth-child(4) {
  border-bottom: 1px solid var(--color-ededed);
}
.categories > div:nth-child(1),
.categories > div:nth-child(2),
.categories > div:nth-child(3) {
  border-right: 1px solid var(--color-ededed);
}
.categories > div:nth-child(5),
.categories > div:nth-child(6),
.categories > div:nth-child(7) {
  border-right: 1px solid var(--color-ededed);
}
/* categories section ends here */
/* Home Page Ends */

/* BLog Part */


.blog-card {
  background-color: white;
  height: 380px;
  border-radius: 10px;
}
.blog-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 50%;
}
.blog-content {
  padding: 15px;
  height: 50%;
}
.blog-content .date {
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--color-777777);
  padding: 0;
}
.blog-content h5 {
  font-size: 18px;
  color: var(--color-131921);
}
.blog-content p.desc {
  font-size: 13px;
  line-height: 22px;
  color: var(--color-777777);
}
.single-blog-card a {
  font-size: 18px;
  color: var(--color-777777);
  margin-bottom: 20px;
}
.single-blog-card h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-1c1c1b);
}
.single-blog-card p {
  color: var(--color-777777);
  font-size: 14px;
}
/*  Blog Part ends here */


.materials li {
  transition: ease 0.3s;
}

.materials li:hover {
  color: var(--color-febd69);
}


/* Product Card */
.featured-collection .col-3,
.popular-products .col-3{
  height: 270px;
}


.product-card {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden ;
  width: 250px;
  height: 100%;
  
}
.advertisement-bar span,
.laptop-col,
.jersey-col,
.main-product-image2 >div{
  overflow: hidden ;

}




.laptop-store{
  width: 100%;
  grid-template-columns: repeat(4, 1fr) !important;
}

.jersey-col{
  width: 100%;
}

.jersey-col .product-card{
  width: 100%;
}

.laptop-col{
  padding: 2px;
}


.product-title{
  font-size: 14px !important;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}


.product-image {
  overflow: hidden;
 
}

.product-image img {
 animation: zoomOut 0.3s ease-in-out forwards; /* Default animation to return to original state */
}

.product-card:hover .product-image img{
  animation: zoomIn 0.3s ease-in-out forwards; /* Trigger zoom animation on hover */

}


@keyframes zoomIn {
  0% {
    transform: scale(1); /* Original size */
  }
  100% {
    transform: scale(1.2); /* Zoomed size */
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.2); /* Zoomed size */
  }
  100% {
    transform: scale(1); /* Original size */
  }
}


 

.product-image {
  height: 170px !important;
}
.product-card .product-image img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.product-card .product-image > img:last-child {
  display: block;
}
.product-card:hover .product-image > img:nth-child(1) {
  display: block;
}
.product-card:hover .product-image > img:last-child {
  display: block;
}
.product-card:hover .action-bar {
  right: 15px;
}
.product-card .product-details h6 {
  color: var(--color-bf4800);
  font-size: 13px;
}
.product-card .product-details h5 {
  font-size: 15px;
  color: var(--color-1c1c1b);
}
.product-card .product-details p.price {
  font-size: 16px;
  color: var(--color-1c1c1b);
}
.product-card p.description {
  font-size: 13px;
  color: var(--color-777777);
  margin-right: 20px;
}
.action-bar {
  top: 10%;
  right: -23px;
  transition: 0.3s;
}
.wishlist-icon {
  top: 2%;
  right: 15px;
}

.price{
  overflow: hidden;
}

span{
  overflow: hidden;
}

.prod-count p{
  overflow: hidden;
}
/* Product Card ends here */

/* Special Product */



.special-card-main{
  width: 250px ;
  height: 360px ;
  padding: 0px;
}

.special-product-card {
  border-radius: 10px;
  background-color: white;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0px;
}

.section-heading{
  margin-bottom: 10px;
}

.special-card-img{
  width: 100%;
  display: flex;
  align-items: center;
  height: 170px;
  position: relative;
}

.special-image{
  width: 100%;
  height: 100%;
}

.special-product-content{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.special-product-content h6{
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
color: #0c0907;
}

.special-product-content h5{
  font-size: 13px;
  font-weight: 500;
  color: #bf4800;
}

.special-product-content .price .red-p{
  font-size: 15px;
  font-weight: 600;
}

.special-product-content{
  padding: 0px 8px;
}

.special-product-content p{
  line-height:15px;
  align-items: center;
  display: flex; 
  margin: 0px !important;
}

.special-product-content .react-stars{
  line-height: 25px;
}

.prod-count{
  margin-top: 5px;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.prod-count p{
line-height: 12px;
font-size: 13px;
}

.overlay{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(92, 95, 236, 0.6);
  justify-content: center;
  display: flex;
  align-items: center;
}

.overlay{
  visibility: hidden;
}

.special-card-main:hover .overlay{
  visibility: visible;
}

.special-card-main:hover .special-image{
  padding: 4px;
}

.limited-offers{
  background-color: #232f3e;
    margin-top: 5px;
  
}



.clock-data{
  display: flex;
  justify-content: center;
  gap: 5px;
}

.time1{
  margin-bottom: 0px;
}

.time3{
  font-size: 18px;
  align-items: center;
  margin-bottom: 0px;
  margin: 0px 5px;
}

.time-left{
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px;
}

.limited-heading{
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 0px;
  line-height: 50px;
  padding-left: 10px;
}
/* Special Product ends here */




/*Popular prducts*/


/*Popular prducts end*/

.covers-card{
  color: var(--color-232f3e);
}

/*featured collection*/


.progress{
  height: 10px;
}
/*featured collection*/

/* famous card */

.famous-card .famous-content {
  top: 10%;
  left: 5%;
}
.famous-card img {
  border-radius: 10px;
}
.famous-content h5 {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400px;
  color: white;
  margin-bottom: 7px;
  text-transform: uppercase;
}
.famous-content h6 {
  font-size: 24px;
  line-height: 38px;
  font-weight: 500;
  color: white;
}
.famous-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 100;
  color: white;
}
/* famous card ends */
.popular-wrapper{
  margin: 10px 0px;
}
/* Our Store */
.filter-card {
  background-color: white;
  border-radius: 10px;
  padding: 15px 15px 25px;
}
.filter-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 20px;
}
.filter-card ul,
.filter-card .form-check-label {
  list-style-type: none;
  font-size: 13px;
  line-height: 30px;
  color: var(--color-777777);
  cursor: pointer;
}
.filter-card .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.25);
}
.filter-card .form-check-input:checked {
  background-color: var(--color-febd69);
  border-color: var(--color-febd69);
}
.sub-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 15px;
  margin-top: 20px;
}
.colors {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.colors li {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
}
.random-products h5 {
  font-size: 14px;
  margin-bottom: 8px;
}
.random-products:first-child {
  border-bottom: 1px solid var(--color-ededed);
}
.filter-sort-grid {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}
.grid img {
  width: 35px;
  height: 35px;
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
}
.gr-12 {
  width: 100%;
}

.grid-cat{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr) !important;
}

.gr-6 {
  width: 49%;
}
.gr-4 {
  width: 32%;
}
.gr-3 {
  width: 24%;
}
.gr-12 .product-card {
  display: flex;
  gap: 20px;
}
.gr-6 .product-image {
  width: 100%;
}
.gr-6 .product-image img {
  margin: auto;
  display: block;

}



.gr-12 .product-card .product-image {
  width: 200px !important;
}

.gr-12 .product-card .product-image {
  height: auto;
}
.gr-12 .action-bar {
  top: 17%;
}
/* Our store ends here */

/* Contact Page */
.contact-inner-wrapper {
  padding: 30px 20px;
  border-radius: 15px;
  background-color: white;
  gap: 15px;
}
.contact-inner-wrapper > div {
  width: 48%;
}
.contact-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}
.contact-inner-wrapper form .form-control {
  background-color: var(--color-f5f5f7);
  border-radius: 10px;
  border-color: transparent;
  padding: 10px;
}
.contact-inner-wrapper ul {
  list-style: none;
}
.contact-inner-wrapper address,
.contact-inner-wrapper a,
.contact-inner-wrapper p {
  color: var(--color-777777);
}
/* Contact Page ends */

/* Compare Product Page */

.compare-product-card {
  padding: 20px 15px;
  background-color: white;
  border-radius: 10px;
}
.compare-product-card .cross,
.wishlist-card .cross {
  top: 10px;
  left: 10px;
  width: 20px;
  padding: 3px;
  cursor: pointer;
}
.compare-product-details h5.title,
.wishlist-card h5.title {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  color: var(--color-1c1c1b);
}
.compare-product-details h6.price,
.wishlist-card h6.price {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
}
.compare-product-card .product-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/* Compare Product Page end here */

/*  auth card */

.auth-card {
  padding: 40px;
  background-color: white;
  width: 430px;
  margin: 30px auto;
  border-radius: 10px;
}
.auth-card h3 {
  font-size: 19px;
  font-weight: 500;
  color: var(--color-777777);
}
.auth-card p {
  font-size: 13px;
  color: var(--color-777777);
}
.auth-card .form-control {
  height: 50px;
  background-color: var(--color-f5f5f7);
  color: var(--color-777777);
  font-size: 14px;
  border-radius: 10px;
  border-color: transparent;
}
.auth-card a {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-1c1c1b);
}
.signup {
  background-color: var(--color-febd69);
  color: var(--color-131921);
  border: none;
}
.signup:hover {
  background-color: var(--color-131921);
  color: white;
}

.login-btn,
.signup-button,
.forgot-password-btn,
.reset-password-btn {
  width: 100%;
  border-radius: 10px;
}

.cancel-btn{
  width: 100%;
  border: 1px solid var(--color-ededed);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 28px;
  border-radius: 10px;

}

.login-wrapper {
  height: 100vh;
  align-items: center;
  display: flex;
}

.signup-wrapper {
  height: max-content;
  align-items: center;
  display: flex;
}


.google-btn-container{
  width: 100%;
 
  display: flex;
  align-items: center;
  justify-content: center;
}


.google_btn {
  width: 230px;
  height: 47px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 20px 0;
  color: #2c444e;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.google_btn > img{
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.google_btn > span{
  margin-left: 10px;
}


.signup-note {
  width: 100%;
  display: flex;
  align-items: center;
}

.signup-element {
  text-decoration: underline;
}

.or-container {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 15px ; /* Adjust the spacing around the container as needed */
}

.or-hr {
  width: 45%;
  border: none;
  height: 1px;
  border-top: 1px solid rgb(189, 188, 188); /* Adjust the line color and thickness */
}

.or-text {
  font-size: 13px;
  font-weight: 500; /* Make the text bold, if desired */
  color: #666; /* Adjust the text color */
}


.email-verification-wrapper h1 {
font-size: 20px;
}

/* Policy Pages */

.policy {
  background-color: white;
  padding: 30px;
}
/* Policy Pages  ends */

/* Main Product Page */

.description-wrapper h4,
.reviews-wrapper h3 {
  font-size: 26px;
  color: #1c1c1b;
  margin-bottom: 10px;
}
.description-wrapper p {
  font-size: 14px;
  color: var(--color-777777);
}

.review-inner-wrapper {
  background-color: white;
  padding: 30px;
}
.review-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.review-head h4 {
  font-size: 18px;
  color: var(--color-1c1c1b);
}
.review-head p,
.review-head a {
  font-size: 14px;
  color: var(--color-777777);
}
.review-form h4 {
  font-size: 16px;
  color: var(--color-777777);
}
.review-form {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.review:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.review p {
  font-size: 14px;
  color: var(--color-777777);
}

.single-product{
  width: 100%;
gap: 10px;
}

.single-pr-details{
  width: 78%;
  padding: 0px;
  background-color: white;
}

.pr-image{
  height: 100%;
  background-color: white;
  width: 43%;
  padding-top: 20px;
}

.about-item h4{
  font-size: 18px;
  font-weight: 500;
}

.about-item p{
  font-size: 12px;
}

.color-div{
  border-bottom: 1px solid rgb(213, 208, 208);
}

.prod-details{
  flex-grow: 1;
  padding-left: 0px !important;
}

.pr-shipping1,
.pr-shipping2{
  background-color: white;
  width: 20%;
  height: max-content;
  padding-top: 30px;
}



.pr-shipping1 p,
.pr-shipping2 p{
font-size: 13px;
}

.pr-shipping1 b,
.pr-shipping2 b{
font-size: 14px;
}

.pr-shipping1 h3,
.pr-shipping2 h3{
font-size: 17px;
}

.pr-shipping1 a,
.pr-shipping2 a{
  color: #3b4149;
  font-size: 12px;
}

.main-product-image {
  border-radius: 10px;
  height: 37%;
}

.main-product-image2 {
  border-radius: 10px;
  height: 68%;
  justify-content: center;
  display: flex;
}


.main-product-image > div {
  height: 80%;
}

.image-part2{
  height: 50%;
  

}



.main-product-image img,
.main-product-image2 img{
  object-fit: cover;
  height: 380px;
  width: 100%;
}



.other-product-images {
  margin-top: 20px;
  padding: 4px;
  height: 12%;
  width: 100%;
  gap:3px;
  padding-left: 10px !important;
  overflow-x: auto;
}


.other-product-images > div {
  width: 90px;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;

}



.other-product-images > div:hover{
  border: 1px solid rgba(0, 0, 0, 0.25);

}

.other-product-images > div > img{
  height: 90px;
  width: 100%;
  object-fit: cover;
  display: flex;
  opacity: 0.7;
}

.other-product-image.active > img {
  opacity: 1;
  border: 1px solid cadetblue;
}

.main-product-image .js-image-zoom__zoomed-image {
  left: 50px !important;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  z-index: 1000;
}


.main-product-image {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.main-product-image img {
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.main-product-image.next img {
  animation: slideNext 0.5s forwards;
}

.main-product-image.prev img {
  animation: slidePrev 0.5s forwards;
}

@keyframes slideNext {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slidePrev {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}


.main-product-image img {
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.main-product-image.next img,
.main-product-image.prev img {
  opacity: 1;
}


.main-product-details {
  padding: 30px 20px;
  background-color: white;
  border-radius: 10px;
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.main-product-details h3.title {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 10px;
}
.main-product-details p.price {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.main-product-details a.review-btn,
.t-review {
  color: var(--color-777777);
  font-size: 13px;
}
.product-heading {
  font-size: 14px;
  color: var(--color-1c1c1b);
  margin-bottom: 0px;
}
.product-data {
  font-size: 13px;
  columns: var(--color-777777);
  margin-bottom: 0px;
}
.main-product-details a {
  font-size: 14px;
  color: var(--color-777777);
}


.mpesa-logo{
  width: 70px;
  height: 40px;
}
/* Main Product Page */

/* Cart Page */
.cart-header,
.cart-data {
  border-bottom: 1px solid #eaeaea;
  
}
.cart-header h4 {
  font-size: 13px;
  color: var(--color-777777);
}
.cart-col-1 {
  width: 40%;
}
.cart-col-1 p {
  color: var(--color-777777);
  font-size: 14px;
}



.cart-data h5.price {
  font-size: 14px;
  color: var(--color-1c1c1b);
}
/* Cart Page Ends here */

/* Shippping Page */

.total {
  font-size: 14px;
  color: var(--color-1c1c1b);
}
.total-price {
  font-size: 16px;
  color: var(--color-777777);
}
/* Shipping page ends here */

.error {
  font-size: 12px;
  color: red;
}


/*Special Products*/



/*Special Products End*/

/*New Arrival*/

.new-arrival{
  width: 100%;
  top: 0;
}

.new-arrival img{
  width: 100%;
  height: 150px;
}

/*New Arrival End*/

.famous-cards{
  overflow-x: auto;
}


.cart-col-2,
.cart-col-3,
.cart-col-4{
  display: flex;
 justify-content: center;
 align-items: center;
 width: 20%;
}



h5 {
  margin: 0px;
}

.row2{
  gap: 5px;
}

.row1{
  display: none;
}

hr{
  display: none;
}


.discount-cover{
  height: 100vh;
  width: 320px;
  padding: 0px;
}



.monthly-card{
  width: 100%;
  align-items: center;
  display: flex;
  top: 19%;
}

.edition-img-card img{
  width: 100%;
  height: 100vh;
}

.monthly-header{
  width: 100%;
  align-items: center;
  display: flex;
  top: 7%;
}

.edition-btn{
  color: #eaeaea;
  background-color: #232f3e;
  padding: 10px;
  border-radius: 12px;
}

.edition-btn:hover{
  background-color: #febd69;
  color: #232f3e;
  transition: 0.3s;
}

.new-offer-heading{
  background-color: #777777;
  width: 100%;
  color: #ededed;
  height: 30px;
  display: flex;
  justify-content: center;
}

.new-offer-heading h4{
  font-size: 20px;
  font-weight: 500;
  padding-left: 10px;
  align-items: center;
  display: flex;
  margin-bottom: 0px;
}

.market1{
  width: 75%;
  display: flex;
  gap: 10px;
}

.first-banner{
  width: 27%;
}

.last-banner{
  width: 25%;
}

.middle-banner{
  flex-grow: 1;
}


.discount-banners{
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}


.discount-banners div{
  padding: 2px;
  background-color: white;
}


.markets-card{

  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  transition: 0.1s;
margin-top: 10px;

}



.markets-card:hover{
 color:orange;
}



.markets-img{
  height: 30px;
  width: 30px;
}

.my-markets{
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.home-wrapper-1{
  background-color: #f5f5f7;
}

.left-section{
  height: 100%;
  position: relative;
}

.banners1{
  background-color:white;
  height: 100%;
  padding: 20px 0px 20px 20px;
  line-height: 40px;
  width: 100%;
  line-height: 60px;
  position: absolute;
}

.banners1 .li{
  padding-right: 20px;
}

#sub-container{
  position: absolute;
  overflow-y: auto;
  width: 745px;
  height: 100%;
  visibility: hidden;
  background-color: white;
  transform: translateX(240px);
  top: 0px;
  z-index: 2;
  padding: 30px 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto; 

}

#sub-container h3{
  font-size: 14px;
}

#sub-container ul{
  padding: 0px;
}

#sub-container li{
  line-height: 25px;
}

#sub-container li a{
color: var(--color-777777);}

#sub-container li a:hover{
color: var(--color-232f3e);
}
  

.li:hover #sub-container{
  visibility: visible;
}

.banners1 li,
.banners1 .li{
  font-size: 12px;
  font-weight: 400px;
  color: #232f3e;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;

}

.banners1 li img,
.banners1 .li img{
  width: 30px;
  height: 30px;
}



.banners1 li:hover{
  color: #bf4800;
}

.side-nav{
  position: absolute;
  display: none;
  right: 100%;
  min-width: 713px;
  height: 470px;
  background-color: white;
  z-index: 1;
}

.apparel:hover .side-nav{
  display: flex;
  justify-content: space-between;
}

.side-nav div{
  align-items: flex-start;
  width: 150px;
  display: flex;
  flex-direction: column;
}

.side-nav h1{
  color: #232f3e;
  text-align: center;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid grey;
}

.side-nav ul{
  list-style-type: none;
  width: 100%;
  padding: 0px;
}

.side-nav ul li{
  color: #232f3e;

}

.wrapper-img{
  height: 120px;
  width: 150px;
}

.discount-banners h1{
  font-size: 20px;
  font-weight: 400px;
}

.pr-details p{
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0px;
}


.wrapper-img:hover{
  padding: 5px;
  transition:  .2s;
}

.collection-wrapper p{
color: #3b4149;
}

.latest-blogs{

  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.latest-blogs .col-3{
  width: 300px;
}

.servies{
  height: 150px;
}

.famous-cards{
  gap: 5px;
}

.famous-card{
  width: 250px;
}




.laptop-store{
grid-template-columns: repeat(4, 1fr);
}

.react-multiple-carousel__arrow{
visibility: hidden !important;
}

.row:hover .react-multiple-carousel__arrow{
  visibility: visible !important;
}

.pr-shipping2{
  display: none;
}


.h-upper-links2{
  display: none;
}

.special-card-img{
  padding: 10px;
}

.special-card-main{
  height: 290px;
}

.special-product-card{
  border-radius: 0px;
  height: 100%;
}

.discount-image{
  width: 100%;
  height: 100%;
}

.supreme-wrapper{
  padding-top: 20px;
}

.supreme-card{
  height: 400px;
  width: 300px;
  padding: 15px;
  background-color: white;
}

.supreme-card > div{
  height: 80%;
}

.supreme-card h4{
  font-size: 18px;
  font-weight: 500;
  margin-top: 12px;
}

.supreme-image{
  width: 100%;
  height: 100%;

}

.discover-link{
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.supreme-wrapper .col-12,
.home-wrapper-2 .col-12{
  padding: 0px !important;
}



 .react-multi-carousel-list{
  padding: 0px !important;
 }

.indulge-card .col-12{
  padding: 0px !important;
}


.electronics h3{
  font-size: 20px;
padding-left: 10px;
}

.product-description{
  width: 100%;
}

.description{
  width: 100%;
  }

  .specifications{
    width: 100%;
  }

  .specifications h4{
    border-bottom: 1px solid var(--color-ededed);
    font-size: 15px;

  }

  .cart2-product > div{
    border-bottom: 1px solid var(--color-ededed);
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .specifications  div{
    border: 1px solid var(--color-ededed);
  }

  .specifications h3{
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid var(--color-ededed);
   display: flex;
   align-items: center;
   padding: 10px;
  }

  .specifications p{
    padding: 10px;
    font-size: 12px;
  }

  .description ul{
    list-style: none;
    border-bottom: 1px solid var(--color-ededed);
   padding: 0px ;
   width: 100%;

   gap: 5px;
  }


  .description-sidebar li {
   position: relative;
    width: 25%;
    text-align: center;
  }

  .description-sidebar li::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px; /* Thickness of the underline */
    width: 100%;
    background-color: #c7c4c4; /* Underline color */
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }


  .description-sidebar li:nth-child(1)::after {
    transform: scaleX(1);

  }

  .description li:nth-child(1) a{
    color: #9e9d9d ;
  }

  .desc-header::after{
    transform: scaleX(1);
  }

  .description li a {
    transition: ease 0.3s;
  }

  .description li:hover a {
    color: #9e9d9d;
  }
  
  .description li:hover::after,
  .description li.active::after {
    transform: scaleX(1);
  }

  .description ul li a{
    color: var(--color-1c1c1b);
    font-size: 14px;
    font-weight: 500;
  }

 



.desc-col-1{
  width: 74%;

} 

.other-products-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.related-products-wrapper{
  background-color: #fff;
}

.related-products-heading,
#review {
  width: 100%;
  background-color: #ffff;
  margin-bottom: 1px;
  font-size: 20px;
  padding: 16px;
}

.other-products-hr{
  background-color: var(--color-ededed);
  width: 100%;
  height: 2px;
}


.cart2-product{
  position: sticky;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  flex-direction: column;
  background-color: white;
  height: max-content;
  top: 0px;
  width: 25%;
}



.description-sidebar{
  position: sticky;
  top: 0px;
  background-color: white;
  line-height: 40px;
  z-index: 2;
}

.description-sidebar a ,
.cart2-product h4{
  font-size:15px !important;
}


.cart2-img{
  padding: 8px;
}

.section-c{
  padding: 0px 100px;
}


.home-sidebar{
  left: 300px;
  height: 100%;
  width: 250px;
  position:absolute;
  top: 50px;
  z-index: 2;
}

.pet-categories{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.pet-categories div{
  height: 150px;
}

.pet-cat-img{
  height: 100%;
}

.categories-wrapper{
  height: max-content;
  background-color: white;
  border-radius: 12px;
  gap: 10px;
  margin-left:11px;
 padding: 5px 0px;
 top: 60%;
 width: 100%;
}

.top-cover2{
  height: 375px;
}

.top-cover2 img{
  height: 100%;
}

.categories-wrapper{
  top: 68%;
}

.categories{
  top: 50%;
  width: 100%;
background-color: #bf4800;
}

.single-product .col-12 {
  display: flex;
}

.pr-shipping1 {
  flex-grow: 1;
}

.pr-shipping1 > h3 {
  border-bottom: 1px solid rgb(0, 0, 0,0.10);
  margin: 0px !important;
  padding-bottom: 8px;
}

.categories-card{
  width: 130px;
  align-items: center;
  height: 100%;
  margin-right: 5px;
}

.categories-card p{
  text-align: center;
  font-size: 14px;
  height: 20%;
}



.categories-card  img{
  width: 100%;
  min-height: 135px !important;

}


 li.react-multi-carousel-item{
  width: 100% !important;
  height: 100%;
}


.owl-nav .disabled {
  display: none;
}

.kitchen-categories{
 display: flex;
 justify-content: space-between;
  top: 70%;
  gap: 10px;
}

.kitchen-categories > div {
  height: 230px;
  grid-template-columns: repeat(3, 1fr);
  padding: 5px;
  background-color: white;
}

.kitchen-categories > div > div{
  height: 100%;
  width: 170px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.kitchen-categories > div img{
border-radius: 65px;
height: 50%;
width: 80%;

}

.kitchen-categories p{
  text-align: center;
}

.kitchen-img1{
  border-radius: 0px !important;
  height: 100% !important;
}

.top-cover img{

 height: 450px;
}



.color-card{
  width: 40px;
  height: 40px;
  padding: 1px;
  border: 1px solid var(--color-ededed);
  margin-bottom: 10px;
}

.color-img{
  width: 100%;
  height: 100%;
}

.printing-form h3{
 font-size: 12px;
}

.size-wrapper h3{
font-size: 14px;
margin-bottom: 0px;
}

.size-wrapper{
  padding: 10px 0px;
}


.Radio-group{
  display: flex;
  gap: 5px;
}

.menu-btn{
  visibility: hidden;
}

.electro-logo{
  gap: 5px;
  padding: 5px;
}

.h-upper-links{
  width: 32% ;
}

.h-upperlinks1{
  width: 25% ;
}

.search-el{
  flex-grow: 1;
}

.sidebar{
  width: 320px;
  flex-shrink: 0;
  background-color: var(--color-131921);
  height: 100vh;
  overflow: scroll;
  transition: 850ms;
  position: fixed;
  z-index: 10;
  visibility: hidden;
  left: -100%;
  top: 0px;
}

.sidebar.active{
  left: 0px;
  transition: 350ms;
}

.sidebar-item{
  padding: .75em 1em;
  display: block;
  transition: background-color .15s;
  border-radius: 5px;
}
.sidebar-item:hover{
  background-color: rgba(255, 255, 255, .1);
}

.sidebar-header{
  height: 85px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 25px;
  font-weight: 500;
  padding: 10px;
  border-bottom: 3px solid rgba(255, 255, 255, .1);
}


.sidebar-title{
  display: flex;
  font-size: 1.2em;
  justify-content: space-between;
}

.sidebar-title span{
  color: white;
}

.sidebar-title span i{
  display: inline-block;
  width: 1.5em;
}
.sidebar-title .toggle-btn{
  cursor: pointer;
  transition: transform .3s;
  color: white;

}
.sidebar-item.open > .sidebar-title .toggle-btn{
  transform: rotate(180deg);
}
.sidebar-content{
  padding-top: .25em;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content{
  height: auto;
}

.sidebar-item.plain{
  color: #fff;
  text-decoration: none;
}
.sidebar-item.plain:hover{
  text-decoration: underline;
}
.sidebar-item.plain i{
  display: inline-block;
  width: 1.7em;
}

.servies{
  width: 100%;
}

.services-card{
  width: 280px;
}

.kitchen-categories >div >div{
  align-items: center;

}

.kitchen-categories >div >div img{
  height: 60%;
}

.the-clock{
  display: none;
}

.left-arrow,
.right-arrow{
  visibility: hidden;
  transition: ease-in-out 0.5s;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  padding: 4px;
  font-size: 40px;
  font-weight: 500;
}

.middle-banner:hover .left-arrow,
.middle-banner:hover .right-arrow{
  visibility: visible;
}

/*pagination*/
.pagination1{
  list-style: none;
  display: flex;
  justify-content: right;
  font-size: 1.2rem;
  gap: 8px;
  height: 30px;
  width: 100%;
  align-items: center;
  padding-right: 20px;
}


.pagination1 .page-num{
height: 100%;
padding: 0px 8px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
  background-color: white;
  color: #1c1c1b;
}

.pagination1 .page-num:hover{
  background-color: #febd69;
  color: white;
}

.pagination1 .active{
  background-color: #febd69;
  color: white;
}
/*pagination end*/
.size-container{
width: 100px;
}

.options{
  background-color: white !important;
  color: #bf4800;
}

.Radio-group{
  width: max-content;
}

.wishlist-btn{
  background-color: transparent;
  border: none;
}

.advertisement-bar{
  height: 50px;
  background-color: var(--color-febd69);
  color: #ffff;
}
.advertisement-bar span{
  font-size: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.advertisement-bar span b{
  font-size: 25px;
  font-weight: 500;
  height: 100%;
  padding: 3px;
}

.animated-text {
  display: inline-block;
  animation-name: dropAnimation;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes dropAnimation {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slanted-background {
  position: relative;
  display: flex;
 align-items: center;
 padding-right: 10px !important;
 text-align: center;
 color: #ffff;
}

.slanted-background:before,
.slanted-background:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #000000;
  z-index: -1;
 
}

.slanted-background:before {
  left: 0;
  transform: skew(-15deg);
  transform-origin: top left;
}

.slanted-background:after {
  right: 0;
  transform: skew(-15deg);
  transform-origin: top right;
}


.abv{
  top: 3%;
  color: #bf4800;
  font-size: 12px;
}

.abv-card{
  background-color: #bf4800;
  color: #eaeaea;
  padding: 1px 8px;
  border: none;
}

.order-header{
  background-color:white;
}


.the-clock{
  display: none !important;
}

.empty-cart-wrapper{
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.order-pr{
  width: 300px;
  gap: 5px;
}

.order-card{
  background-color: var(--color-ededed);
}

.order-image{
  width: 100px;
  height: 100px;
}

.order-details p{
  font-size: 14px;
}

.order-status-p{
  border: 1px solid var(--color-ededed);
  padding: 5px;
}

.color-img{
  width:30px;
  height: 30px;
  border: 1px solid var(--color-ededed);
  padding: 1px;
}

.cart-col-4{
  align-items: center;
  display: flex;
  justify-content: center;
}

.supreme-cards{
  width: 280px;
  height: 350px;
  position: relative;
  align-items: center;
}
.supreme-cards h4{
  color: white;
  font-weight: 500;
  font-size: 30px;
}

.supreme-cards .discover-link{
  background-color: white;
  padding: 4px;
  width: 90px;
  font-size: 14px;
  height: 30px;
}

.supreme-details {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: max-content;
}


.fill-heart{
  color: orangered;
}


/* LoadingCircles.css */

.loading-circles {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  animation: scale 1s infinite alternate;
}

.circle1 {
  animation-delay: 0s;
}

.circle2 {
  animation-delay: 0.2s;
}

.circle3 {
  animation-delay: 0.4s;
}

@keyframes scale {
  0% {
    transform: scale(1);
    background-color: #ccc;
  }
  100% {
    transform: scale(1.5);
    background-color: #333;
  }
}


.discount-banners {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.discount-banner-pair{
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(2, 1fr);
gap: 5px;
background-color: transparent !important;
}

.scrollable-banner {
  overflow-x: scroll;
  white-space: nowrap;
  padding: 2px;
  background-color: white;

  /* Hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */

  /* Webkit-based browsers */
  &::-webkit-scrollbar {
    width: 0.5rem; /* Adjust this to set the width of the invisible scrollbar space */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  pointer-events: none; /* This allows clicks to pass through the overlay */
  &.active {
    pointer-events: auto; /* This will enable capturing click events on the overlay when active */
  }
}


.overlay2.active {
  pointer-events: auto; /* Allow clicks on the overlay when active */
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--color-232f3e);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 99;
}

.back-to-top.visible {
  opacity: 1;
}


/* YourComponent.css */

.supreme-wrapper{
  padding: 0px;
}

.supreme-container{
  display: flex;
  height: 250px;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.supreme-card1 {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Hide any overflowing content */
  display: flex;
}

.supreme-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Make the image cover the card */
}

.supreme-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay color */
  z-index: 2; /* Place overlay above the image */
  transition: opacity 0.3s ease-in-out; /* Transition for overlay fade */
  opacity: 1; /* Initially transparent */
}

.supreme-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px; /* Add some padding to the content */
  text-align: center;
  color: white; /* Text color for clarity */
  z-index: 3; /* Place content above the overlay */
}

.discover-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  position: relative;
}

.hover-line {
  position: absolute;
  bottom: -5px; /* Adjust the position of the line */
  left: 0;
  width: 0; /* Initially hidden */
  height: 2px;
  background-color: white;
  transition: width 0.3s ease-in-out; /* Transition for line width */
}

.discover-link:hover .supreme-overlay {
  opacity: 1; /* Make the overlay visible on hover */
}

.discover-link:hover .hover-line {
  width: 100%; /* Expand the line width on hover */
}


/*property*/

.previous-price {
  text-decoration: line-through;
  font-style: italic;         
  font-weight: 300;           
  color: #888;    
  margin-bottom: 0px; 
  font-size: 13px !important;
}

.price {
  margin-bottom: 0px;
}



.delivery-icon{
  font-size: 25px;
  margin-right: 8px;
}

.return-icon{
  font-size: 23px;
  margin-right: 8px;

}


.number-element {
  width: 100%;
  gap: 20px;
  margin-bottom: 10px;
}

.number-wrapper {
  display: flex;
  align-items: center;
  width: 50%;
}

.number-wrapper p {
  margin: 0px;
}

.prefix {
  width: 35%;
  padding-left: 10px;
}

.number{
  width: 90%;
}

.checkout-wrapper .col-7{
width: 55%;
padding: 0px 20px;
}

.checkout-wrapper .col-5{
  flex-grow: 1;
  padding: 0px 20px;

}

.border-element div {
  align-items: center;
  display: flex;
}
/* CSS */
.property-wrapper {
  position: relative;
  overflow: hidden;
}


.property-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Adjust the overlay color and opacity as needed */
z-index: 2;
}

.property-main-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container */
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.property-main-details {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff; /* Adjust text color as needed */
  z-index: 2; /* Ensure the details are above the images */
}


.cover-btn{
  border: none;
  padding: 5px;
  transition: 0.3s ease-in-out;
  background-color: white;
  color: #1c1c1b;
}

.cover-btn:hover{
  background-color: var(--color-febd69);
  color: white;
}

.property-cover{
  display: flex;
  height: 480px;
}

.property-store{
  grid-template-columns: repeat(3,1fr);
  gap: 5px;
}


.property-card-cover {
  color: white;
  margin: 0px;
  bottom: 0%;
  display: flex;
}

.property-card-cover p{
  margin: 0px;
}

.property-card-amenities{
  color: white;
bottom: 0%;
right: 5px;  
display: flex;
gap: 5px;
}

.property-card-amenities b{
  font-size: 12px;
  font-weight: 400;
}

.bathtub{
  margin-bottom: 4px;
}

.zoom-effect {
  position: relative;
  overflow: hidden;
  width: 100%; /* Make sure the container takes up the full width */
  height: 100%; /* Make sure the container takes up the full height */
}

.zoom-effect img {
  width: 100%; /* Make sure the image takes up the full width */
  height: 100%; /* Make sure the image takes up the full height */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  transform: scale(1); /* Initial scale, adjust as needed */
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}

.zoom-effect:hover img {
  transform: scale(1.1); /* Adjust the scale factor as needed for your desired zoom level */
}

/*singleproperty page*/
.single-property-container{
width: 100%;
display: flex;
}

.single-property-details{
  width: 80%;
  display: flex;
  flex-direction: column;
}

.main-property-image{
  display: flex;
  justify-content: center;
  padding: 3px;
}

.property-data{
  border: 2px solid var(--color-f5f5f7);
  padding: 4px;
}

.property-btn{
  border: none;
  padding: 4px;
  background-color: var(--color-3b4149);
  color: white;
  margin-top: 5px;
}

.property-btn:hover{
background-color: var(--color-febd69);
}
/*singleproperty page end*/


div[data-rmiz-content="not-found"] {

  height: 2px;
}


.property-categories{
  top: 70%;
}
/*property End*/


/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top of other elements */
}

/* Modal Content */
.modal-content {
  background-color: #fff; /* White background for the modal */
  border-radius: 5px;
  padding: 20px;
  width: 400px; /* Adjust the width as needed */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add a subtle shadow */
}

/* Modal Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #333; /* Dark gray color for the close icon */
}

/* Modal Header (if you have one) */
.modal-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Form Input Styles */
.form-control {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc; /* Gray border for inputs */
  border-radius: 5px;
}

/* Error Message Styles */
.error {
  color: red; /* Red color for error messages */
  font-size: 14px;
  margin-top: 5px;
}

/* Payment Information Container Styles */
.p-container {
  margin-top: 15px;
}

.payment-p {
  font-size: 14px;
  color: #333; /* Dark gray color for text */
}

/* M-Pesa Button Styles */
.mpesa-button {
  margin-top: 20px;
}

.m-btn {
  background-color: #007bff; /* Blue background for the button */
  color: #fff; /* White text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* M-Pesa Button Hover Styles */
.m-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}


/* CSS */
.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.sports-wrapper{
  padding: 0px !important;
}

.sports-wrapper .col-12{
padding: 0px !important;
}



/*fitness*/
.fitness-categories-card{
  width: 200px;
  height: 200px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.fitness-categories-card img{
  width: 100%;
  height: 160px;
display: flex;
}

.fitness-categories-card div{
  height: 160px;

}

.fitness-cat-title{
  justify-content: center;
}

.fitness-categories-card p{
  display: flex;
  justify-content: center;
  margin: 0px;
}
.watch-row{
  display: flex;
  justify-content: center;
}

.watch-store{
grid-template-columns: repeat(4, 1fr);
gap: 10px;
}

.watch-store .product-card{
  width: 300px !important;
}

.watch-card1:hover .supreme-overlay{
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent overlay color */
}

.watch-categories-list{
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.watch-card1{
  width: 100%;
}

.view-all{
  background-color: var(--color-232f3e);
  color: white;
  padding: 5px;
  width: 100px;
  display: flex;
  justify-content: center;
  transition: 0.3s ease-in-out;
}

.view-all:hover{
  background-color: var(--color-febd69);
  color: white;
}
/*fitness end*/

/*New css*/


.badge-container{
  position: absolute;
  top: -20%;
  right: -15%;
  
}


.badge{
  padding: 2px;
}

.popular-wrapper .col-12{
border-bottom: 2px solid var(--color-f5f5f7);
margin-bottom: 3px;

}

.popular-products1{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 0px !important;
}

.product-card{
  border-radius: 0px;
}



.header-upper-links{
  padding: 0px 10px;
}
/*Cart End*/
.login-container{
  gap: 10px;
}

.logins p{
  font-size: 16px;
}

.login-icon{
  font-size: 30px;
}

.login-icon1{
  font-size: 25px;
}

.wishlist-container{
  gap: 10px;
}

.cart-icon,
.wishlist-icon{
  font-size: 30px;
  color: white;
}



/* LoadingCircles.css */

.loading-circles {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  animation: scale 1s infinite alternate;
}

.circle1 {
  animation-delay: 0s;
}

.circle2 {
  animation-delay: 0.2s;
}

.circle3 {
  animation-delay: 0.4s;
}

/* M-Pesa Button Hover Styles */
.m-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}


button{
  background-color: var(--color-232f3e);
  color: #fff;

 
}


.btn-qty {
  
  width: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}



.pc,
.q-btn {
  gap: 4px;
  display: flex;
}


.cart-col-8{
  display: none !important;
}

.form-control{
  margin: 0px;
}

/*Cart*/
.container-xxl{
  padding: 0px;
}

.continue-shopping{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.cart-categories{
width: 100%;
}

.cart-categories img{
  width: 100%;
}


.see-all2{
  display: none !important;
}
/*New css End*/


/* Custom CSS to show Carousel arrows */
.carousel-root {
  position: relative;
}





.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Adjust this value to control how much text is shown before the ellipsis */
}

.cover-image{
  height: 430px;
}

.navigation-buttons{
  bottom: 50%;
  display: none;
  transition: 0.5s;
  padding: 0px 10px;
}

.navigation-buttons div{
  font-size: 25px;
}


.sports-wrapper:hover .navigation-buttons{
  display: flex ;
}

/* CSS */
.visible2 {
  display: block; /* Show the element */
}

.hidden2 {
  display: none; /* Hide the element */
}
 

.dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
};




.dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px; /* Adjust this value for vertical positioning */
  width: 100%;
}

.dot {
  margin: 0 5px; 
  cursor: pointer;
  font-size: 20px;
  color: white;
}

.active {
  font-size: 24px; 
  color: blue;
}

.paginate-container1{
  width: 100%;
  justify-content: center;
  bottom: -20px;
}



.max-jersey{
  font-size: 12px;
  color: var(--color-777777);
  margin: 0px;
}

.average-rating{
  font-size: 50px;
  font-weight: 400;
}

.average-rating span{
  font-weight: 300;
}

.progress-bars {
  display: flex;
  flex-direction: column;
}



.progress-bar-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 5px;
}

.progress-bar {
  height: 10px;
  width: 200px;
  border-radius: 4px;
  background-color:#808080;

}

.progress-bar1{
  background-color: rgb(32, 180, 230);
}

.progress-bar-label {
  margin-left: 8px;
}

.filled-progress {
  height: 100%;
  background-color: #ffd700;

}

.review-container{
  flex-grow: 1;
  padding: 20px;
  position: relative;
}

.review{
  width: 100%;
}

.ratings{
  background-color: #ececec;
}


.pagination{
  position: absolute;
  display: flex;
  justify-content: end;
  bottom: 10px;
  width: 100%;
  gap: 10px;
  align-items: center;
}

.review-arrow{
  background-color: var(--color-232f3e);
  color: white;
  font-size: 23px;
  transition: 0.2s;
}

.review-arrow:hover{
  color: #000000;
  background-color: var(--color-febd69);
}

.wishlist-image{
  width:300px;
  height:250px;
}

.watch-cover{
  height: 400px;
  width: fit-content;
}

.carousel-container {
  max-width: 100%;
  overflow: hidden;
}


.recently-wrapper,
.related-products-wrapper  {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  align-items: center;
}

.cart-data{
  height: 100%;
}

.cart-col-2{
  height: 100%;
}

.cart-col-6{
  height: 100%;
}


.remove-container,
.button1{
right: 10px;
bottom: 10px;
padding: 2px 4px;
color: black;
border: none;
background-color: transparent ;
transition: ease-in-out 0.2s;
}

.remove-container:hover,
.button1:hover{
  color: rgb(117, 117, 117);
}


.remove-icon {
  color: var(--color-febd69);
}

.button1{
  margin-bottom: 10px;
  margin-left: 5px;
}

.subtotal{
  border: 2px solid var(--color-f5f5f7);
  padding: 10px;
}

.brand{
  color: var(--color-febd69) !important;
}

.profile-wrapper{
  width: 100%;
}

.profile-wrapper >div{
  flex-grow: 1;
}



.my-account{
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  background-color: white;
}

.my-account h3{
  font-size: 20px;
  width: 100%;
  padding: 10px;
}

.my-account p{
  padding: 10px;
margin-bottom: 2px;
}

.my-account-img img{
  width: 70px;
  height: 70px;
}

.my-account-details{
margin: 2px;
width: 100%;
transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
transform: scale(1); 
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Set your desired box shadow */
color: #0c0907;
}

.my-account-details:hover{
  transform: scale(1.01);
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2); /* Set your desired box shadow for hover */
}

.profile-header{
  padding: 10px;
  font-size: 25px;
}

.products-list > div{
  grid-template-columns: repeat(4,1fr);
}



 .section-B{
  padding: 0px 30px;
}

.border-bottom-container{
  display: none;
}


.paginate-container-s{
  width: 100%;
  justify-content: center;
 
  bottom: 0;
}


.filter-wrapper{
  width: 20%;
 
  display: flex;
  justify-content: end;
  align-items: center;
  
}

.filter-wrapper button {
  width: 40%;
  border-radius: 10px;
  border: none;
  background-color: transparent;
}

.filter-icon{
  font-size: 25px;
  color:var(--color-1c1c1b);

}

 .paginate-container-s ul{
  margin: 10px 0px !important;
}

.paginate-container-s > div{
   align-items: center;
   display: flex;
}

.loading-img{
  width: 100%;
  height: 100%;
}

.login-dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
}

.login-dropdown-menu a {
  display: block;
  padding: 5px 0;
}

.login-container:hover .login-dropdown-menu {
  display: block;
}


.dropdown-toggle::after{
  display: none !important;
}

.custom-dropdown-toggle {
  background-color: transparent !important;
border: transparent;

}

.custom-dropdown-toggle:hover{
  background-color: transparent !important;
border: transparent;
}


.custom-dropdown-item:hover .custom-dropdown-toggle{
  background-color: transparent !important;

}

.logout-btn,
.signup-btn{
  background-color: #232f3e !important;
  color: white;
  display: flex;
  align-items: center;
}

.logout-btn-container{
  margin-top: 10px;
  padding: 0px 10px;
}

.dropdown-item{
  line-height: 30px;
}


.signup-btn:hover,
.login-container:hover .login-auth,
.login-container:hover .login-icon,
.login-container:hover .login-icon1{
  color: var(--color-febd69); 
}

.price{
  font-weight: 500;
}

.product-title{
  font-weight: 400;
}



.supreme-content span:hover{
  color: var(--color-febd69);
}

.cologne-categories{
  width: 100%;
  height: 230px;
  position: absolute;
  bottom: -7%;
  
}



.cologne-categories-containers{
  width: 100%;
  height: 100%;
  gap: 5px;
  padding: 4px;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.cologne-categories-containers > div{
  width: 100%;
  height: 100%;
overflow: hidden;
border-radius: 4px;
}

.cologne-categories-containers p{
  padding: 5px !important;
  width: 100%;
  bottom: 0%;
  color: white;
  margin: 0px !important;
}

.cologne-categories-containers >div:hover p{
  color: var(--color-febd69);
}

.cologne-categories-containers img{
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.cologne-categories-containers >div:hover img{
  transform: scale(1.1);
}

/*Photo*/

.photo-wrapper{
  margin-top: 5px;
  gap: 5px;
}

.photo-container{
  gap: 5px;
  height: 500px;

}

.photo-cover1{
  width: 75%;
  height: 100%;
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.p-c1{
height: 61%;
}

.p-c1 >img{
  height: 100%;
  width: 100%;
}

.photo-small-img{
  width: 100%;
  gap: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.photo-small-img > img{
  height: 100% !important;

}

.photo-cover1 img{
  padding: 0px !important;
}

.photo-cover2{
  width: 25%;
  height: 100%;
}

.photo-cover2 > img{
  height: 100%;
}


.photo-header{
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--color-3b4149);
  color: white;
  height: 40px;
  align-items: center;
}

.photo-header > h1{
  font-size: 20px;
}

.photo-categories,
.apparel-categories{
  width: 100%;
 display: flex;
}


.photo-categories >div{
  display: grid !important;
  grid-template-columns: repeat(4,1fr) !important;

}

.photo-categories > div > div{
  height: 230px;
}

.apparel-categories > div > div{
  height: 230px;
}

.product-tags{
  max-height: 300px;
  overflow-y: scroll;
}

.top-header-container{
  background-color: #232f3e;

}

.top-header{
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 0px;
    line-height: 50px;
    padding-left: 10px;
}


.apparel-categories > .cologne-categories-containers{
  display: grid !important;
  grid-template-columns: repeat(6, 1fr) !important;
}


.category-overlay {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Adjust the transparency here */
}

.cologne-categories-containers > div:hover .category-overlay{
  background-color: rgba(0, 0, 0, 0.1);
}



.cologne-categories-containers > div:hover .hover-line{
  width: 100%;
}

.shop-by{
  width: 25%;
  padding-right: 0px !important;
}

.filter-container{
  margin-bottom: 10px !important;
}

.brand-categories > .cologne-categories-containers > div{
  height: 100px;
}

.brand-categories > .cologne-categories-containers{
  display: grid !important;
  grid-template-columns: repeat(6, 1fr) !important;
}


.previous-price{
  font-size: 12px;
  font-weight: 400;
}


.border-element {
  background-color: white;
  padding: 10px;
}


.breadcrumb-el-2 {
  display: none;
}


.previous-price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.product-image{
  position: relative;
}



.percentage-difference {
  position: absolute;
  top: 5%;
  right: 5%;
  background-color: #fef3e9;
  color: var(--color-febd69);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 20px;
  text-align: center;
}

.search-wrapper {
  padding-top: 0px !important;
}


.cart-image {
  width: 200px;
}

/*Terms And Conditions*/

.policy ul {
  list-style: none;
}


.policy h1 {
  font-size: 30px;
}

.policy b {
  font-size: 14px;
}

.policy p,
.policy li {
  font-size: 14px;
}

/*My Orders*/

.breadcrumb-row {
  margin: 0px !important;
}

.orders-navbar {
  width: 100%;
 padding: 0px 13px;

}


.orders-navbar ul {
  display: flex;
  list-style: none;
  justify-content: space-around;
  padding-left: 0px;
  width: 100%;
  gap: 5px;
}

.orders-navbar ul li {
  background-color: var(--color-1c1c1b);
  color: white;
 width: 25%;
  text-align: center;
  padding: 10px 0px;
  border: 2px solid var(--color-1c1c1b);
  font-size: 16px;

}

.orders-navbar ul li.active {
  background-color: transparent;
  color: var(--color-1c1c1b);
  border: 2px solid var(--color-1c1c1b);
}


.orders-container-el {
  margin-top: 20px;
  min-height: 330px;
}


.empty-order-el{
width: 100%;
height: 250px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 20px;

}


.orders-btn {
  background-color: var(--color-1c1c1b);
  color: white;

  text-align: center;
  padding: 10px 25px;
  border: 2px solid var(--color-1c1c1b);
  font-size: 16px;
  transition: ease-in-out 0.3s;
}

.orders-btn:hover {
  background-color: transparent;
  color: var(--color-1c1c1b);

}



.my-profile-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-profile-container > div {
 width: 100%;
 background-color: #ffff;
 padding: 30px;
 padding-top: 0px;
}

.my-profile-container h2 {
  font-size: 25px;
  width: 100%;
  padding: 20px 0px;
  text-align: center;
  border-bottom: 2px solid var(--color-ededed);
}

.my-profile-container h1 {
  font-size: 20px;
}

.my-profile-container b ,
.my-profile-container p{
  font-size: 14px;
  margin-bottom: 5px;
}

.shipping-btn {
  padding: 3px 15px;
}


.my-profile-icon{
  font-size: 50px;
}

.profile-element {
  justify-content: space-between;
  display: flex;
}


.my-account-details:hover {
  color: #000000;
}

.profile-wrapper-el {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.edit-profile-container {
  width: 50%;
}

/*---------WISHLIST--------*/

.wishilist-main-wrapper h1 {
  width: 100%;
  text-align: center;
  font-size: 25px;
  padding: 15px 0px;
  border-bottom: 2px solid var(--color-ededed);
}

.social-icon {
  font-size: 18px;
}


.service-icon {
  font-size: 35px;
}



.cancel-order-container {
  position: absolute;
  right: 10px;
  bottom: -25px;
  display: flex;
  align-items: center;
  justify-content: end;

  width: 100%;
}

.btns-container {
  width: 15%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.track-order-btn {
  padding: 2px 20px;
  border: 2px solid green; 
  background-color: transparent;
  color: green;
}

.cancel-order-btn {
  padding: 2px 20px;
  border: 2px solid var(--color-232f3e);
  transition: ease 0.3s;
}

.cancel-order-btn:hover{
  background-color: transparent;
  color: var(--color-232f3e);
}

/*---------Tracking Modal--------*/





.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px); /* Add the blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.modal-content h2{
text-align: center;
font-size: 25px;
}

.modal-content p {
  
  padding: 2px 4px;
}

.tracking-nav {
  list-style: none;
}

.tracking-nav li {
  position: relative;
  padding-right: 30px;
  margin-bottom: 10px; 
}

.tracking-nav li::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  width: 10px;
  height: 10px; 
  border-radius: 50%;
  background-color: var(--color-ededed); 
  transform: translateY(-50%); 
}

.active-nav::after {
  background-color: green !important; 
}

.active-nav{
  color: green ;
}

.tracking-nav span {
  font-size: 16px;
}


.track-icon{
  font-size: 20px;
}

.tracking-hr {
  width: 100%;
  margin-bottom: 10px;
  border-top: 2px solid var(--color-ededed); /* Adjust border color */
}

.tracking-hr1 {
  width: 100%;
  margin-top: 5px;
  border-top: 2px solid var(--color-ededed); /* Adjust border color */
}

.nav-el {
  margin-top: 5px;
}

.message-container {
  display:flex;
  gap: 5px;
  width: 100%;

}

.track-icon-container {
  width: 30px;
  display: flex;
  justify-content: end;
}

.track-icon-2{
  font-size: 30px;
}

.track-no {
  font-size: 14px;
}

/*--------About---------*/



.about {
  display: flex;
  width: 100%;
  padding: 10px 20px;
}
.about-content {
  width: 60%;
}

.about-img-container {
  width: 40%;
  height: 400px;
}

.about-img-container img {
  width: 100%;
  height: 100%;
}


/*--------Counter---------*/


.clients-container {
  display: flex;
  gap: 30px;
  padding: 0px;
  width: 100%;
  justify-content: space-between;
}

.clients-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clients-content h1 {
  color: var(--color-232f3e);
}

.clients-content b {
  color: #BFA37C;
  font-family: 'Inconsolata', sans-serif;
  font-size: 14px;
  letter-spacing: 3px;
}

.clients-container p {
  margin-top: 30px;
  color: #a6a6a6;
  font-size: 16px;
}



.elementor {
  width: 100%;
  display: flex;
  align-items: center;
}

.counters {
  padding: 20px 0px;
  color: var(--color-232f3e);
  text-align: center;
  width: 100%;
  height: 200px;
}

.counters > div {
  max-width: 900px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4em 2em;
}

.counter {
  position: relative;
}

.counter h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
}

.counter h1 span {
  position: relative;
}

.add-icon {
  position: absolute;
  top: -15px;
  right: 0px;
  font-size: 30px;
  color: var(--color-232f3e);
}

.counter:not(:last-child)::before {
  content: '';
  background: var(--color-232f3e);
  position: absolute;
  width: 2px;
  height: 3em;
  top: 50%;
  transform: translateY(-50%);
  right: -1em;
}

.counter h3 {
  font-size: 16px;
  color: #a6a6a6;
}






@media screen and (max-width: 900px) and (min-width: 501px) {
  .counters > div {
      grid-template-columns: 1fr 1fr;
  }
  .counter:nth-child(2)::before {
      display: none;
  }
}

@media screen and (max-width: 500px) {
  .counters > div {
      grid-template-columns: 1fr;
  }
  .counter:not(:last-child)::before {
      width: 90%;
      height: 2px;
      top: initial;
      right: initial;
      bottom: -3em;
      left: 50%;
      transform: translateX(-50%);
  }
}



@media (max-width:780px) {
  
  .about-container-wrapper h1 {
    padding: 10px 20px !important;
  }

  .about {
    padding: 0px 20px;
  }

 .clients-content {
  padding: 10px 20px;
  text-align: center;
 }

  .clients-container {
      flex-direction: column;
      gap: 15px;
  }

  .clients-img-container {
      width: 100%;
      gap: 5px;
  }

  .clients-img-container img {
      width: 50%;
  }


  .elementor {
      width: 100%;
  }

  .counters h1 {
      margin-bottom: 10px;

  }

  .counters {
    height: max-content;
  }
}


.about-section-2{
  margin: 40px 0px;
  padding: 0px 20px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.about-card {
 
  text-align: center;
  background-color: var(--color-ededed);
  position: relative;
 
  padding: 20px 10px;
  border-radius: 10px;
}

.about-card h1 {
  font-size: 25px;
}



.about-icon {
  background-color: var(--color-febd69);
  color: #ffff;
  font-size: 60px;
  padding: 5px;
  border-radius: 10px;
}

.footer-link a {
  transition: ease 0.3s;
 }

.footer-link a:hover {
 text-decoration: underline;
}

.social-icon:hover {
  color: var(--color-febd69) !important;

}


.whatsapp-btn-container{
  width: 100%;
  justify-content: start;
  display: flex;
}

.whatsapp-btn-container button {
  background-color: #128c76;
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  gap: 10px;
}

.whatsapp-btn-container button:hover {
  background-color: #319784;
  color: #fff;
}



.quantity-container {
  align-items: center;
  margin-bottom: 10px;
}




.cart-btn-el {
  padding: 10px 50px;

}

.whatsapp-icon{
  font-size: 20px;
}


.send-icon {
  font-size: 25px;
  color: #fff;
}


.number {
  display: flex;
  width: 100%;
  background-color: #ccc;
  border-radius: 10px;
  position: relative;
  
}

.number-input {
  padding-left: 80px; 
} 

.number span {
 width: 23%;
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: #ccc;
 border-radius: 6px;
 position: absolute;
 height: 100%;
 border-top-right-radius:0;
 border-bottom-right-radius:0;

}


.specifications p{
  margin-bottom: 0px !important;
}



.stock-container{
  width: 100%;
  display: flex;
  justify-content: start;
}

.stock-container .button{
  background-color: rgb(85, 8, 8);
  width: 35%;
}

.out-of-stock-btn{
  background-color: rgb(85, 8, 8);

}

.stock-container .button:hover,
.out-of-stock-btn:hover{
  color: white;
}

.view-btn {
  color: #0056b3 !important;
  text-decoration: underline;
}


.logo-img-container {
height: 40px;
width: 100%;
display: flex;
align-items: center;
gap: 5px;
}

.logo-img-container h1 {
  margin: 0px;
  font-size: 30px;
  color: #fff;
}


.logo-img-container img {
  width: 8%;
  height: 80%;
  
}


.loader img {
  width: 60px;
  height: 60px;
}

.delivery-wrapper-el{
  gap: 5px;

}


.delivery-wrapper-el p{
  margin: 0px;

}

.delivery-header-el {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  margin: 0px;
}


.delivery-content {
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: #fae5e5;
  color: red;
}

.delivery-content img {
  width: 5%;
  height: 5%;
}


.desktop-only {
  display: flex;
}
/*--------Media Query---------*/

@media screen and (max-width: 840px){
  *{
    margin: 0;
    padding: 0;
  }

.electro-logo {
  width: 50%;
  gap: 5px;
}




  
  .logo-img-container h1 {
    display: flex; 
    font-size: 17px;
    }



    .logo-img-container img {
      width: 25%;
      height: 80%;
      
    }
    
  /*advertisement bar*/
  .advertisement-bar span{
    font-size: 20px;
  }
  /*advertisement bar end*/

  .sidebar{
    visibility: visible;
    z-index: 1100;
  }

  .overlay {
    visibility: visible;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    z-index: 1000 ; /* ensure the overlay appears below the sidebar */
  }
  
  body.sidebar-active {
    overflow: hidden; /* Disable scrolling */
  }


.number-input {
   padding-left: 60px; 
 } 

 .number span {
  width: 60px ;
 }



 .address-container{
 
  width: 100% !important;
 }

 .cart-btn-el {
  width: 100%;
 }


 .stock-container .button{
  width: 100%;
}
/*Header*/
.header-top-strip,
.header-top-strip2{
  display: none;
}

.header-upper .col-2,
.header-upper2 .col-2{
margin-bottom: 5px;
  width: 100%;

}

.header-upper,
.header-upper2{
  margin-bottom: 10px;
  padding-bottom: 0px !important;
  width: 100%;
padding-top: 10px !important;
}

.h-upperlinks1{
 display: none !important;
}

.h-upper-links{
display: flex;
justify-content: space-between;
width: 100%;
align-items: center;
}

 

.auth-card{
  width: 100%;
}

.h-upper-links2{
width: 50% !important;
margin-top: 10px;
}

.menu-btn{
  visibility: visible;
  color: #eaeaea;
  font-size: 30px;
  display: flex;
  align-items: center;
}

.left-arrow{
  display: none;
}


.header-upper-links2{
  width: 100%;
  display: flex;
  align-items: center;
}

.fav-wishlist{
  display: none;
}

.logins{
  left: 10px;
  flex-grow: 1;
}

.header-bottom,
.header-bottom2{
  display: none;
}
/*Header End*/

.login-auth{
  font-size: 12px;
}

.h-upper-links2{
  padding: 0px 10px;
  gap: 10px;
}

.logins{
  justify-content: right;
  display: flex;
}

.login-container{
  gap: 5px;
}

.login-container p{
  font-size: 15px;
}

.cart-icon{
  width: 30px;
  height: 30px;
  margin-bottom: -3px;
}



.login-icon{
  width: 30px;
  height: 30px;

}

.login-icon1{
 width: 23px;
  height: 23px;
}




.login-wrapper {
  height: 100vh;
  align-items: center;
  display: flex;
}

/*----------Sidebar---------*/

.sidebar {
  background-color: var(--color-232f3e);
  padding: 5px;

}

.sidebar-btn-container{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}

.sidebar-btn {
  width: 100%;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin-bottom: 5px;
  width: 90%;
  border: 2px solid var(--color-f5f5f7) !important;
}



/*----------Home---------*/


.top-cover-wrapper {
  flex-direction: column;
  height: max-content;
}

.top-cover-1 {
  width: 100%;
  height: 340px;
}

.cover {
  height: 300px;
}

.cover-wrapper {
  margin-bottom: 10px;
  height: max-content;
}


.section-6 {
 margin-bottom: 10px;
 flex-direction: column;
 gap: 10px;
 height: max-content;
}



.single-cover {
  height: 200px;
  width: 100%;
}


.other-covers .cover {
  height: 49%;
}
/*Home-Wrapper*/



.home-wrapper-1,
.home-wrapper-2{
  padding: 0px 10px !important;
}

.row2{
display: none !important;
}

.row1{
  display: flex;
  height: 320px;
  overflow-x: auto;
  display: flex;
}

.row1 .col-3{
  height: 200px;
  width: 90%;
}

.image1{
  height: 300px;
  width: 100%;
}

hr{
  display: block;
  height: 20px !important;
  background-color: #777777;
  margin-bottom: 10px;
  margin-top: 0px;
  width: 100%;
}

/*laptop-store*/

.breadcrumb-el-1 {
  display: none;
}

.breadcrumb-el-2 {
  display: flex;
 background-color: #ffff;
 flex-direction: column;
 margin-top: 10px;
}

.breadcrumb-el-2 h1 {
  font-size: 16px;
  padding: 10px;
  border-bottom: 1px solid grey;
  
}



.breadcrumb-el-2 {
  font-size: 12px;

}

/*laptop-store end*/

.section-B{
  padding: 0px !important;
}

.supreme-container{
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 10px;
  gap: 5px;
  width: 100%;
  height: 460px;
}

.supreme-card1{
  height: 230px;
  position: relative;
  width: 100%;

}


.supreme-card1 >div{
  height: 100%;
  width: 100%;
}

.supreme-card1 img{
  height: 100%;
width: 100%;
}



.indulge-card{
  height: 150px;
}

/*covers*/
.sports-wrapper{
  height: 250px !important;
 
}

.image{
 
  height: 100%;
}

.i-cover{
  height: 100%;
}

 .cover-image{
  height: 100% ;
}


.cover-wrapper {
  display: grid !important;
  grid-template-columns: repeat(1, 1fr);
}
/*covers End*/




/*laptops*/

.the-col{

width: 100%;
}

.filter-container{
  margin-bottom: 5px !important;
  border-radius: 0px;

}

.laptop-col{
  width: 205px;
  height: 293px;
}

.jersey-col{
  width: 205px;
  height: 257px;
}

.product-card{
  margin: 0px !important;
}


.wines-cat{
  overflow-x: auto;
  display: flex;
  gap: 2px;
}


.wines-cat .col-6{
  width: 130px;
  height: 160px;

}

.wines-cat p{
  margin: 0px;
}

.categories-wrapper{
  margin-bottom: 5px;
  border-radius: 0px;

}

.shop-by{
  display: none
}



.laptop-store{
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 3px !important;

}


.cart-btn-el {
  padding: 10px 28px;
  justify-content: center;
}

.whatsapp-btn-container button {
  width: 100%;
  justify-content: center;
}
/*laptops end*/


.featured-heading,
.popular-heading{
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0px;
  background-color: #232f3e;
  color: #eaeaea;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  width: 100%;
}

.container-xxl{
  padding: 0px;
}

.product-card{
  margin-top: 15px;
}



.supreme-card{
  height: 350px;
}

.supreme-card .discover-link{
  color: #1c1c1b;
  font-size: 12px;
}

.supreme-wrapper{
  padding: 0px !important;
}



.sports-wrapper img{
  height: 150px;
}
/*Home-Wrapper End*/

.limited-wrapper{
  margin-bottom: 5px;
}

.limited-offers{
  display: flex;
  width: 100%;
  height: 35px;
  padding: 0px;
  margin-top: 0px;
}

.limited-offers h3{
  font-size: 20px;
}

.time-left-card{
  display: none !important;
}

.limited-offers-row{
  display: flex ;
  padding: 0px ;
  background-color: #232f3e;
}

.limited-heading{
  padding: 0px;
}

.the-clock{
  width: 100%;
  display: flex !important;
  gap: 5px;
  padding: 0px;
}


.special-product-content .price p{
  font-size: 12px;
  font-weight: 600;
}


/*Services wrapper*/

.service-wrapper{
  padding: 0px !important;

}



/*Services wrapper*/


/*Store*/


.search-wrapper .col-3{
  display: none;
}



.filter-sort-grid{
  width: 100%;
  margin-bottom: 10px !important;
}

.search-list{
width: 100%;
grid-template-columns: repeat(2, 1fr) !important;
gap: 4px;
display: grid ;
}



.search-card{
  grid-template-columns: repeat(1, 1fr) !important;
display: grid ;
}

.search-container{
  width: 100%;
}

.search-container .products-list{
  gap: 0px !important;
}

.products-count{
  display: none !important;
}



.paginate-container1{
  align-items: center;
  width: 100%;
}

.pagination1{
  justify-content: center;
  padding: 0px !important;
  width: 100%;
}

.breadcrumb{
  padding: 15px 0px !important;
}

.special-product-card2{
  padding-bottom: 5px;
}
/*Store End*/


/*Banners*/

.discount-cover{
  display: none;
}

.discount-banners{
  overflow-x: auto;
}

.discount-image{
  height: 140px;

}

/*Banners End*/

/*services*/

.servies{
  height: 120px;
}

.services-card{
  height: 100%;
  margin-right: 20px;
}

.services-content{
  width: 160px;
}

/*services end*/


/*Products*/


 .product-image{
  height: 160px !important;
}

.product-title{
  white-space: nowrap;       
  overflow: hidden;          
  text-overflow: ellipsis;  
  height: 20px;
  margin-block-end: 0px;
}

.price{
  margin: 0px;
}


.featured-collection .col-3{
  height: 250px !important;
}

.popular-products .col-3{
  width: 190px !important;
  height: 235px !important;
}

.popular-products .product-card{
  padding: 0px ;
  margin: 0px;

}
/*Products End*/

/*Special Products*/

.special-products2{
  display: grid;
grid-template-columns: repeat(2, 95%);
overflow-x: auto;
}

.special-product-card2{
  width: 100%;
  gap: 10px;
}

.special-card-img2{
  width: 30%;
  padding: 2px;
}

.special-card-img2 img{
  width: 100% !important;

}

.special-product-card2 .special-product-content{
  width: 68%;
}

/*Special Products End*/


.reviews-wrapper {
  padding: 0px !important;
  margin-top: 5px;
}

.review-inner-wrapper {
  margin: 0px;
}

.breadcrumb {
  padding: 10px !important;
}


#review,
.related-products-heading {
  margin: 0px !important;
  padding: 10px ;
}


.mobile-images-container .mobile-image-wrapper {
  display: flex;
  overflow-x: auto;
  gap: 10px; /* Adjust the spacing between images as needed */
  padding: 10px 0;
  scroll-snap-type: x mandatory;
}

.mobile-images-container .mobile-image-wrapper img {
  scroll-snap-align: start;
  width: 100%; /* Adjust the width to control the size of the images */
  height: 100%;
}



.main-product-details {
  padding: 8px;
}


.cart-bottom-element {
  display: flex;
  width: 100%;
  justify-content: space-between;
}


.remove-container-2 {
  background-color: transparent;
  color: #000000;
  border: none;
}



.related-products-heading {
  background-color: #ffff;
  width: 100%;
}

.cart-data {
  margin-bottom: 0px !important;
}

.card-wrapper {
  padding: 0px !important;

}

.subtotal-wrapper {
  margin: 8px 0px !important;
}

.checkout-wrapper .col-7{
  width: 100%;
}

.number-element {
  flex-direction: column;
}

.number-wrapper {
  width: 100%;
}

.checkout-left-data nav{
padding: 0px 0px 10px !important;

}

.checkout-left-data ol {
  padding: 0px !important;
}
/*Tagline wrapper*/

.home-wrapper-2 >div{
  overflow-x: auto;
}

.tagline{
  width: 350px;
  background-color: #bf4800;
}

/*Tagline wrapper*/

/*Feautured-collection*/

.featured-collection,
.special-products,
.popular-products{
  display: flex;
  overflow-x: auto;
}

.featured-collection .col-3,
.special-product-card,
.popular-products .col-3{
  width: 190px;
  height: 100%;
  border-radius: 0px;
}


.featured-wrapper{
  padding: 0px !important;
}

.featured-heading,
.popular-heading{
  padding-left: 0px;
}
/*Feautured-collection*/

/*Special-product*/

.special-card-main{
  height: 270px;
}

.special-product-card{
  height: 100%;
}

.special-card-img{
  height: 160px;
}

.special-product-card .title,
.special-product-card2 .title {
  white-space: nowrap;       
  overflow: hidden;          
  text-overflow: ellipsis;  
}



.ellipsis {
  text-overflow: ellipsis;   /* Apply ellipsis to long titles */
}

.special-product-card .price{
  margin: 0px;
}

.prod-count{
  margin-top: 8px !important;
}

.prod-count p{
  margin-bottom: 5px;
}

/*Special-product End*/

/*famous-cards*/

.famous-cards{
 max-height: 320px;
  display: flex;
  overflow-x: auto;
  flex-direction: column;
}
.famous-cards .col-3{
  height: 100%;
  min-width: 250px;
}


/*famous-cards End*/



.top-cover{
  display: none;
}

.kitchen-categories{
  flex-direction: column;
padding: 10px !important;
position: relative !important;
height: 400px;
margin-bottom: 5px !important;
}

.kitchen-categories >div{
  gap: 10px !important;
  padding: 0px !important;
}

.kitchen-categories >div >div{
  height: 100%;
}

.top-cover2{
  display: none;
}

.categories-wrapper{
  position: relative !important;
}




/*singgle product*/

.related-products-heading,
.recently-viewed-heading,
#review{
  font-size: 16px;
}

.review-head h4 {
  font-size: 14px;
}

.review-head span {
  font-size: 12px;
}

#write-review {
  font-size: 14px;
}

.form-control {
  font-size: 12px;
}

.pr-shipping2 > h3 {
  font-size: 16px !important;
}

.product-heading {
  font-size: 14px !important;
}

.product-data {
  font-size: 12px !important;
}



.cart-image{
  width: 220px;
  height: 200px;
}

.related-products-heading,
.recently-viewed-heading{
  font-size: 16px;
  margin-top: 10px;
}


.button {
  font-size: 12px;
}

.reviews-wrapper p {
  font-size: 12px;
}

.product-title {
  font-size: 13px !important;
}



.pr-shipping2{
  padding: 10px 16px;
}

#review{
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.review-inner-wrapper {
  padding: 10px 16px;
}

.product-card{
  width: 100%;
}

.single-product{
  flex-direction: column;
}

.single-product .col-6{
  width: 100%;
}

.cart2-product{
  display: none;
}

.li4{
  visibility: hidden;
}

.desc-col-1{
  width: 100%;
}

.description-sidebar{
  padding: 5px !important;
  gap: 5px;
height: 50px;
position: sticky;
top: 0px;
}

.main-product-wrapper {
  margin-bottom: 10px !important;
}

.product-description{
  margin-bottom: 10px !important;
}

.section-c{
  padding: 0px !important;
}

.write-review{
  display: none;
}

.single-pr-details{
  display: grid !important;
 width: 100%;
  grid-template-columns: repeat(1, 1fr);
}

.pr-image{
  height: 100%;
}

.main-product-image{
  height: 80%;
  justify-content: center;
  display: flex;
}

.other-product-images{
  padding-left: 18px;
}


.pr-shipping1{
  display: none;
}

.pr-shipping2{
  display: flex;
  flex-direction: column;
  width: 100%;
}



/*singgle product End*/

/*Cart*/
.cart-details1{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px !important;
  height: 140px;
  justify-content: space-between;
}

.cart-top-element {
  display: flex;
  flex-direction: column;
}

.cart-details1 p{
  margin: 0px !important;
}

.cart-col-2{
  margin: 8px 0px !important;
}

.pc{
  display: none !important;
}

.cart-col-8{
  padding-left: 0px !important;
  gap: 10px;
}



.cart-header{
display: none !important;
}

.cart-data{
flex-direction: column;

}

.cart-col-1{
  width: 100%;
}

.cart-col-2{
  width: 100%;
  display: flex;
  justify-content: start;
  margin: 4px;
}

.cart-col-4{
  display: none;
}

.cart-col-3{
  display: flex;
  flex-grow: 1;
}

.cart-col-6{
  justify-content: space-between;
  width: 100%;
}

.cart-col-8{
  padding: 0px 5px;
}

.subtotal{
  width: 250px;
}

.subtotal p{
 display: none;
}

.badge-container{
  right: 1px;
  top: -5px;
}
.cart-col-6{
  display: none;
}

.cart-col-8{
  display: flex !important;
 flex-direction: column;
}

.cart-image {
  height: 140px;
  padding: 5px;
  width: 30% ;
}

.cart-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.quantity-element button {
  color: #fff;
  background-color: var(--color-232f3e);
}

.btn-qty {
  border: none;
}

.contacts{
  display: none;
}

.recently-viewed-container {
  width: 100% !important;
  display: flex;

}

.related-products-wrapper {
  width: 100% !important;
 
}

.related-products-collection {
  width: 100%;
  
}

.product-card {
  flex-shrink: 0;
}




.checkout-wrapper {
  padding: 10px !important;
}


.checkout-product-container {
  padding: 5px;
}


.checkout-product-container img {
  width: 100px;
  object-fit: cover;
}


.checkout-product-container .total-price {
  width: 140px;
}


.select-full-width {
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.checkout-img{
  width: 200px !important;
}

.checkout-img img{
  width: 100% !important;
  height: 100% !important;
}

.border-bottom-container2{
  display: none;
}
/*Cart End*/

.search-wrapper,
.cart-wrapper,
.empty-cart-wrappe,
.empty-order-el {
  padding: 0px !important;
}

.breadcrumb {
  margin-top: 0px !important;
  font-size: 12px;
}

.edit-profile-container {
  padding: 0px 10px !important;
}

.edit-profile-container > h3 {
  font-size: 24px !important;
}

.edit-profile-container h3 {
  font-size: 20px;
}
.form-label {
  font-size: 14px !important;
}

.profile-header {
  font-size: 20px !important;
}

.profile-container h3 {
  font-size: 16px;
  margin: 0px;
}

.profile-container p {
  font-size: 12px;
}

/*footer*/


.footer-one{
  display: flex;
 flex-direction: column;
}

.col-5{
  display: flex;
  width: 100%;
  margin-bottom: 12px;
}

.col-7{
  width: 100%;
}

.col-13{
  display: none;
}


.footer-logo {
  display: none;
}

.newsletter-container {
  flex-direction: column;
  width: 100%;
}


.newsletter-container .col-5,
.newsletter-container .col-7 {
  width: 100%;

}


.footer-top-data {
  
  display: flex;
 gap: 10px !important;
  width: 100%;
  justify-content: center;
 
}

.footer-one .col-5 {
  width: 100%;
}

.footer-top-data img {
  width: 20px;
  height: 20px;
}

.left-footer{
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  padding: 0px 20px;
 width: 100%;
}

.left-footer .col-3{
  width: 100%;
}

.right-footer{
  width: 100%;
}

.right-footer .col-4{
  width: 100%;
}

.footer-2{
  display: grid;
 grid-template-columns: repeat(1, 1fr);

}

.social_icons{
  margin: 0px !important;
}

.discount-wrapper{
  padding: 0px !important;
}

.discount-banners{
  overflow-x: auto;
  gap: 0px;
}

.discount-banner-pair{
  overflow-x: auto;
  flex-wrap: nowrap;
}

.popular-products1{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.product-card1{
  width: 100%;
}

.popular-wrapper .col-12{
  border: none ;
}

.popular-wrapper .col-3{
  width: 100% ;
}


.see-all{
  display: none !important; 
}

.see-all2{
  display: flex !important;
  justify-content: right;
}

.sidebar-btn{
  background-color: transparent;
  border: none;
  margin-left: 2px;
}



.sports-wrapper{
  height: 200px;
  display: flex;
  padding: 0px 10px !important;
  margin-bottom: 10px;
}

.sports-wrapper .row{
  height: 100%;
}

.sports-wrapper .col-12{

  display: flex;
}

.sports-wrapper img{
  height: 100%;
}


 


/*Product End*/

.signup-wrapper {
  height: 100vh;
  align-items: center;
  display: flex;
}
/*Property*/
.single-property-container{
  margin: 0px;
}

.single-property-details{
  width: 100%;
  padding: 0px;
}



.property-img1 > div > img{
  width: 100% !important;
  height: 300px !important;
}

.property-wrapper{
  height: 350px;
}

.property-cover{
  height: 350px;
}

.property-store{
  grid-template-columns: repeat(2, 1fr);
}




/*Property End*/
.watch-categories-list{
  grid-template-columns: repeat(2,1fr);
}

.watch-card1{
  width: 100% !important;
}

.supreme-image1{
  width: 100%;
}

.watch-row{
  padding: 0px !important;
}

.watch-store{
  grid-template-columns: repeat(2,1fr);
}

.watch-card{
  width: 100%;
}

.watch-card > .product-card{
  width: 201px !important;
padding: 10px;
}

.profile-container{
  padding: 0px !important;
}

.my-account{
  grid-template-columns: repeat(2,1fr);
}


.remove-container{
  display: none;
}

.cart-data{
  padding: 0px !important;
}

.recently-viewed-container{
  padding: 0px !important;
}

.recently-wrapper{
  overflow-x: auto;
}

.related-products-collection {
  display: flex;
  flex-wrap: nowrap; 
}

.viewed-container > .product-card,
.related-products-collection > .product-card{
 
  width: 160px ;
  height: 185px ;
}

.viewed-container > .product-card > .product-image,
.related-products-collection > .product-card > .product-image{
  width: 100% !important;
  height: 60% !important;
}

.related-products-wrapper{
  overflow-x: auto;
  width: 100%; 
}


/* CSS for the MediaSidebarItem component with overlay */
/* CSS for the sidebar overlay */
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust overlay color and opacity as needed */
  z-index: 999; /* Place the overlay above other content */
  cursor: pointer; /* Show a pointer cursor to indicate it's clickable */
}

.overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 99; /* Ensure the overlay appears above other content */
  pointer-events: none; /* Allows clicks to pass through when not active */
  transition: opacity 0.3s ease-in-out;
}

.overlay2.active {
  pointer-events: auto; /* Allow clicks on the overlay when active */
}


.wines-col{
  width: 204px;
}

.border-bottom-container{
  display: flex;
  flex-direction: column;
}

.border-bottom {
  padding: 10px 0px !important;

}

.products-element {
  height: 100px;
  align-items: center;
}


.products-element img {
  width: 70px;
  height: 50px;
}


.title-element {
  flex-grow: 1;
 
}

.title-element h5,
.title-element  {

  width: 100% !important;
 
}
.title-element .total-price,
.title-element p,
.pr-title{
  font-size: 13px;
  color: var(--color-777777);
}

.border-bottom .total-price {
  color: var(--color-bf4800);
}

.delivery-header {
  font-size: 18px;
}

.total-element .total{
  font-size: 16px;
}

.checkout-item{
  padding: 10px !important;
}

.return-cart-btn{
  display: none !important;
}

.checkout-img{
  width: 200px !important;
}

.checkout-img img{
  width: 100% !important;
  height: 100% !important;
}

.border-bottom-container2{
  display: none;
}

.button1{
  display: none;
}

.subtotal{
  width: 100%;
}

.subtotal > div{
  margin-bottom: 10px;
  border-bottom: 2px solid var(--color-f5f5f7);
}

.subtotal b:nth-child(1) {
  font-size: 14px;
  
}

.col-7, 
.col-9,
.col-12,
.col-5,
.col-2{
  padding: 0px !important;
}

.row{
  margin: 0px !important;
}



.image-container{
  padding: 0px;
}

.main-product-image2{
  height: fit-content;
}

.other-product-images{
  height: 60px;
}

.quantity-container{
  flex-direction: column;
}

.quantity-container > div{
  width: 100%;
  justify-content: space-between !important;
}

.quantity-container > .c-btn{
  justify-content: center !important;
  display: flex;
  width: 100%;
  margin-left: 0px !important;
}

.c-btn > button{
  width: 100%;
}

.q-btn{
  width: 120px;
  justify-content: space-between;
  display: flex;
}

.ratings-container{
  flex-direction: column;
}

.review-arrow{
  font-size: 25px;
}

.related-container{
  padding: 0px !important;
}

.laptop-store .jersey-col{
  width: 100%;
}

.store-wrapper,
.laptop-col,
.filter-container,
.property-store,
.property-list, 
.spring,
.laptop-row,
.the-col{
  width: 100% !important;
}


.pr-container .filter-container{
  width: 100% !important;
}

.property-store .property-col{
  width: 100% !important;
}

.search-container{
  overflow-y: hidden;
}

.search-container .paginate-container1{
  bottom: -10px;
}


/*Fonts*/
/*Sidebar Fonts*/
.sidebar-title,
.sidebar-btn,
.sidebar-item{
  font-size: 16px;
}

.sidebar-content{
  font-size: 15px;
}

/*Sidebar Fonts End*/

/*Cart*/


/*Cart End*/

/*Covers*/

.discover-link{
  font-size: 15px;
}
/*Covers End*/
 
/*Fonts End*/
.cologne-categories-containers >div{
  height: 130px !important;
}


.cologne-categories-containers p{
  padding: 0px !important;
margin: 0px !important;
bottom: 0px;
}


.store-wrapper{
  padding: 0px !important;
}


.apparel-categories > .cologne-categories-containers{
  grid-template-columns: repeat(4 ,1fr) !important;
}

.watch-cover1{
  height: 320px;
}

.watch-cover-container{
  height: 100%;
}

.watch-cover{
  height: 100% !important;

}

.cologne-categories{
  position: relative;
  height: max-content;
}

.cologne-categories p {
  font-size: 15px;
}

/*--------ORDERS--------*/


.orders-wrapper {
  padding:  0px !important;
}

.col-16 {
  padding: 0px;
}

.order-details .col-3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*--------ORDERS END--------*/

.number-element p {
  font-size: 12px;
  text-align: end;
 
}

.number-wrapper {
  gap: 5px;
}

.footer-el{
  padding:  16px !important;
}



.footer-el-top,
.footer-el-bottom{
  padding: 16px 0px !important;
}

.footer-top-data h2 {
  font-size: 20px;
}


footer .col-3:nth-child(2) {
  
  display: flex;
  flex-direction: column;
 align-items: center;
}


footer .col-3:nth-child(2) > div{
width: max-content;
}


.media-footer {
  padding: 0px;
}

.media-footer h4 {
  font-size: 16px;
  margin-bottom: 12px !important;
}

.media-footer a {
  font-size: 12px;
}

.right-footer {
  padding: 0px;
}


.social-icons-container {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 0px;
}

.social_icons {
  gap: 0px !important;
  width: 30%;
  justify-content: space-between;
}

.social-icon {
  font-size: 16px !important;
}

.media-footer .col-3 {
  width: 45%;
}

.media-footer {
  display: flex;
  padding: 0px;
  gap: 10px;
  width: 100%;
}

.contacts-el {
  width: 100%;
}

.c-wrapper {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}


.footer-el-bottom p {
  font-size: 12px;
}


.loader {
  width: 100%;
  height: 100vh;
}

.desc-image img{
  height: 200px;
}

.desc-image div {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.ratio {
  margin-top: 5px;
}

/*----------My Account---------*/

.my-profile-container {
  padding: 5px !important;
}

.edit-profile-container {
  width: 100%;
  margin-top: 0px !important;
}

.orders-container-el {
  margin-top: 0px !important;
}

.orders-navbar {
  padding: 0px;
}

.orders-navbar ul {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.orders-navbar li {
  flex: 0 0 150px; /* Each item takes up exactly 150px */
  text-align: center;
  padding: 10px; 
  cursor: pointer;
}


.recently-viewed-section{
  padding: 0px !important;
}


.checkout-wrapper {
  margin-top: 0px !important;
  padding: 5px !important;
}

.address-data {
  padding: 10px;
}


.wishlist-image {
  height: 150px;
  width: 200px;
}

.wishlist-el{
  padding: 0px !important;
}


.wishlist-el p {
  font-size: 16px;
}

.wishlist-wrapper {
  margin-top: 0px !important;
}

.contact-inner-wrapper {
  flex-direction: column;
}

.contact-inner-wrapper div {
  width: 100%;
}

/*----------Policy---------*/


.policy {
  padding: 10px !important;
}

.policy h1 {
  font-size: 20px;
}


.policy ul {
  padding-left: 12px;
}

/*----------Order---------*/

.cancel-order-container {
 padding: 0px ;
 bottom: 0px;
 margin-top: 10px;
}

.btns-container {
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.track-order-btn,
.cancel-order-btn {
  padding: 2px 10px;
}

.order-status-p {
  padding: 4px 20px !important;
}



.description-sidebar {
  overflow-x: auto;
  white-space: nowrap;
}

.description-sidebar li {
  width: 40% !important;
  padding: 0px 5px;
  border: 2px solid var(--color-232f3e);
  background-color: var(--color-232f3e);
  
}

.description-sidebar li::after {
  transform: scaleX(0) !important;

}


.description-sidebar a {
  color: #fff !important;
}

.desc-header{
  background-color: transparent !important;
  color: var(--color-1c1c1b);
  border: 2px solid var(--color-1c1c1b);
}

.desc-header a {
  color: var(--color-232f3e) !important;
}

/*--------About---------*/

.about {
  flex-direction: column;
}

.about-content {
  width: 100%;
}


.about-img-container{
width: 100%;
}


.about-section-2 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 20px;
  align-items: center;
  justify-content: center;
}


.shop-by-media {
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  overflow: auto;
  background-color: #fff;
  z-index: 1000;
}

.filter-back-btn-wrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.filter-btn-container,
.filter-btn-container1{
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.filter-btn-container{
  justify-content: space-between;
}

.filter-btn-container button{
  width: 100%;
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
}


.filter-btn-container button:nth-child(1){
background-color: transparent;
color: #000000;
}


.filter-btn-container1 h1 {
  margin: 0px;
  font-size: 25px;
  color: var(--color-232f3e);
}

.filter-back-icon{
  font-size: 30px;
 color: var(--color-232f3e);
}




.delivery-wrapper-el p:nth-child(2){
 font-size: 13px;

}

.animated-text b {
  font-size: 16px !important;
}

.desktop-only {
  display: none;
}


.other-covers {
  flex-direction: column;
  width: 100%;
  gap: 10px;
  height: 400px;
}

.cover-content h1,
.cover-content-2 h1{
  font-size: 23px;
}

.section-6 h1 {
  font-size: 22px;
}
/*footer End*/
}


