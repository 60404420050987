@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;700&display=swap');



.testimonial-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 6em 1em;
    border-radius: 8px;
  }
  
  .left-col-el {
    width:45%;
    margin-right: 20px;
  }
  
  .left-col-el h2 {
    font-size: 30px;
    margin-bottom: 1.5em;
    padding-left: 40px;
    font-weight: 700;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  .testimonial-content {
    display: flex;
    gap: 0.3em;
    position: relative;
    padding-left: 2.5em;
    flex-direction: column;
  }

  .testimonial-icon {
    font-size: 2.5em;
    position: absolute;
    left: -1.5%;
    top: -1%;
  }
  
  .testimonial-content p {
    line-height: 2em;
    font-size: 18px;
    margin-bottom: 10px;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
  
  .testimonial-author {
    font-weight: bold;
    color: #555;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
  
  .right-col-el {
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .testimonial-image {
    max-width: 100%;
    border-radius: 2em;
    object-fit: cover;
    height: 350px;
  }
  

  @media screen and (max-width:784px) {

    .testimonial-wrapper {
        flex-direction: column;
        gap: 1.5em;
        padding: 6em 0.5em;
        padding-bottom: 2em !important;
    }

    .left-col-el {
        width: 100%;
    }

    .testimonial-image {
        border-radius: 1em;
    }

  }